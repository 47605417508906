import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../formfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import men_member from '../../img/man-member.png';
import women_member from '../../img/women-member.png';
import { editProfile, getMemberDetails } from "../../redux/memberDetails";
import Loading from "../Loading";

function EditProfile() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [mobNav,setMobNav]=useState(false);

    let memberid = JSON.parse(localStorage.getItem('member-info')).member_id;
    const { loading } = useSelector((state) => state.membersDetails);
    const [tab, setTab] = useState(1);
    let memberDetails=JSON.parse(localStorage.getItem('member-info'));

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [Roles, setRole] = useState("");
    const [AadharCard, setAadhaar] = useState("");
    const [ScDate, setJoinDate] = useState("");
    const [addressFL, setAddressFL] = useState("");
    const [addressSL, setAddressSL] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [locality, setLocality] = useState("");
    const [RefInf, setRefInf] = useState("");
    const [VettingInf, setVetInf] = useState("");

    function editProfileForm(event) {
        event.preventDefault();
        let memberData = { memberid, Roles, gender, email, lastName, middleName, firstName, addressFL, addressSL, city, state, country, zipcode, locality, AadharCard, ScDate, RefInf, VettingInf };
        dispatch(editProfile(memberData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/profile");
            }
        });
    }

    useEffect(() => {
        dispatch(getMemberDetails(memberid)).then((result) => {
            if (result.payload.error === "") {
                let memberdata = result.payload.data;
                setFirstName(memberdata.fname);
                setLastName(memberdata.lname);
                setMiddleName(memberdata.mname);
                setEmail(memberdata.email);
                setAddressFL(memberdata.fladdress);
                setAddressSL(memberdata.sladdress);
                setLocality(memberdata.locality);
                setCity(memberdata.city);
                setCountry(memberdata.country);
                setState(memberdata.state);
                setZipcode(memberdata.zipcode);
                setGender(memberdata.gender);
                setAadhaar(memberdata.aadhar);
                setJoinDate(memberdata.science_date);
                setRefInf(memberdata.ref_inf);
                setVetInf(memberdata.vetting_inf);
                setRole(memberdata.role);
            }
        });
    }, [dispatch, memberid]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit {memberDetails.fname}'s Profile | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Profile" valueMobNav={mobNav}/>
                <div className="right-total-body">
                    <HeaderNav navValue={["Profile", "Edit"]} />
                    <div className="fulldetailsbody">
                        <form onSubmit={(event) => editProfileForm(event)}>
                            <div className="toolbar-container">
                                <Link to={"/profile"} className="my-button bg-yellow"><i className="fas fa-user-alt"></i> Back to Profile</Link>
                                <button type="submit" className="my-button bg-lightgreen"><i className="far fa-edit"></i> Complete</button>
                            </div>
                            <div className="myRow">
                                <div className="col-md-9">
                                    <div className="tab-container">
                                        <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Basic Details</div>
                                        <div className={(tab === 2) ? "tab selected" : "tab"} onClick={() => setTab(2)}>Residential Details</div>
                                    </div>
                                    <div className="details-formcontainer">
                                        <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">First Name</label>
                                                        <input className="input-cell" required="required" type="text" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Middle Name</label>
                                                        <input className="input-cell" type="text" placeholder="Enter Middle Name" onChange={(e) => setMiddleName(e.target.value)} defaultValue={middleName} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Last Name</label>
                                                        <input className="input-cell" required="required" type="text" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="profile-pic-cont">
                                                        <img src={gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-upimg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Email</label>
                                                                <input className="input-cell" disabled type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} defaultValue={email} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Gender</label>
                                                                <select className="input-cell" onChange={(e) => setGender(e.target.value)} required value={gender}>
                                                                    <option value="" disabled="">Select any one</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                    <option value="others">Others</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Aadhaar No.</label>
                                                                <input className="input-cell" required="required" type="number" max={999999999999} min={100000000000} placeholder="Enter Aadhaar No." onChange={(e) => setAadhaar(e.target.value)} defaultValue={AadharCard} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={(tab === 2) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Country</label>
                                                        <input className="input-cell" required="required" type="text" placeholder="Enter Country" onChange={(e) => setCountry(e.target.value)} defaultValue={country} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">State</label>
                                                        <input className="input-cell" required="required" type="text" placeholder="Enter State" onChange={(e) => setState(e.target.value)} defaultValue={state} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">City</label>
                                                        <input className="input-cell" required="required" type="text" placeholder="Enter City" onChange={(e) => setCity(e.target.value)} defaultValue={city} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Address First Line</label>
                                                        <input className="input-cell" required="required" type="text" placeholder="Enter Address First Line" onChange={(e) => setAddressFL(e.target.value)} defaultValue={addressFL} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Address Second Line</label>
                                                        <input className="input-cell" type="text" placeholder="Enter Address Second Line" onChange={(e) => setAddressSL(e.target.value)} defaultValue={addressSL} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Locality</label>
                                                        <input className="input-cell" type="text" placeholder="Enter Locality" onChange={(e) => setLocality(e.target.value)} defaultValue={locality} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Zipcode</label>
                                                        <input className="input-cell" required="required" type="text" placeholder="Enter Zipcode" onChange={(e) => setZipcode(e.target.value)} defaultValue={zipcode} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Reference Information</label>
                                                        <textarea className="input-cell" placeholder="Enter Reference Information" onChange={(e) => setRefInf(e.target.value)} defaultValue={RefInf}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Vetting Information</label>
                                                        <textarea className="input-cell" placeholder="Enter Vetting Information" onChange={(e) => setVetInf(e.target.value)} defaultValue={VettingInf}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 small-padding-r">
                                    <div className="mandetory-cont big-panel">
                                        <div className="title">Mandatory Information</div>
                                        {(firstName === "") ? <div className="alert alert-danger" role="alert">Need to fill First Name in Basic Details tab</div> : ""}
                                        {(lastName === "") ? <div className="alert alert-danger" role="alert">Need to fill Last Name in Basic Details tab</div> : ""}
                                        {(email === "") ? <div className="alert alert-danger" role="alert">Need to fill Email in Basic Details tab</div> : ""}
                                        {(gender === "") ? <div className="alert alert-danger" role="alert">Need to fill Gender in Basic Details tab</div> : ""}
                                        {(Roles === "") ? <div className="alert alert-danger" role="alert">Need to fill Role in Basic Details tab</div> : ""}
                                        {(ScDate === "") ? <div className="alert alert-danger" role="alert">Need to fill Joining Date in Basic Details tab</div> : ""}
                                        {(AadharCard === "") ? <div className="alert alert-danger" role="alert">Need to fill Aadhaar No. in Basic Details tab</div> : ""}
                                        {(country === "") ? <div className="alert alert-danger" role="alert">Need to fill Country in Residential Details tab</div> : ""}
                                        {(state === "") ? <div className="alert alert-danger" role="alert">Need to fill State in Residential Details tab</div> : ""}
                                        {(city === "") ? <div className="alert alert-danger" role="alert">Need to fill City in Residential Details tab</div> : ""}
                                        {(addressFL === "") ? <div className="alert alert-danger" role="alert">Need to fill Address First Line in Residential Details tab</div> : ""}
                                        {(zipcode === "") ? <div className="alert alert-danger" role="alert">Need to fill Zipcode in Residential Details tab</div> : ""}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            {
                loading ? <Loading Type="dna" /> : ""
            }
        </>
    )
}
export default EditProfile;
