import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../LoginandRegister.css';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import logo from '../../img/icon/logo.jpg';
import { loginMember } from '../../redux/memberAuth';

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let [errorMsg, setErrorMsg] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function loginForm(event) {
        event.preventDefault();
        let loginDatas = { email, password };
        dispatch(loginMember(loginDatas)).then((result) => {
            if (result.payload.error === "") {
                navigate("/dashboard");
            } else {
                setErrorMsg(result.payload.error);
            }
        });
    };

    useEffect(() => {
        if (localStorage.getItem('member-info')) {
            navigate("/dashboard");
        }
    }, [navigate])

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Employee Login | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <div className='full-Container myRow'>
                <div className='login-form'>
                    <div className='logo-form'>
                        <img src={logo} alt='Logo' />
                    </div>
                    <div className='texttitle-form'>
                        Employee Login
                    </div>
                    <form onSubmit={(event) => loginForm(event)}>
                        <div className='myRow'>
                            <div className='icon-cont'>
                                <i className="fas fa-user-shield"></i>
                            </div>
                            <div className='input-cont'>
                                <div className="form__group field">
                                    <input type="email" className="form__field" placeholder="Enter Email" id="useremail" required onChange={(e) => setEmail(e.target.value)} />
                                    <label className="form__label">Email</label>
                                </div>
                            </div>
                        </div>
                        <div className='myRow'>
                            <div className='icon-cont'>
                                <i className="fas fa-key"></i>
                            </div>
                            <div className='input-cont'>
                                <div className="form__group field">
                                    <input type="password" className="form__field" placeholder="Enter Password" id="password" required onChange={(e) => setPassword(e.target.value)} />
                                    <label className="form__label">Password</label>
                                </div>
                            </div>
                        </div>
                        <div className='myRow'>
                            {
                                errorMsg && (<div className="myRow"><div className='login_error'>{errorMsg}</div></div>)
                            }
                        </div>
                        <button type="submit" className="myloginbtn">Login</button>
                    </form>
                </div>
            </div>
        </>
    );
}
export default Login;