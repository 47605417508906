import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllPayment = createAsyncThunk(
    'visistpayment/getallpayment',
    async (memberuser_id) => {
        let result = await fetch(serverHost + "api/visistpayment/getallpayment/" + memberuser_id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addPayment = createAsyncThunk(
    'visistpayment/addnewpayment',
    async (visitPaymentData) => {
        let result = await fetch(serverHost + "api/visistpayment/addnewpayment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitPaymentData)
        })
        result = await result.json();
        return result;
    });
export const updatePayment = createAsyncThunk(
    'visistpayment/updatepayment',
    async (visitPaymentData) => {
        let visitPaymentDatas = visitPaymentData;
        let result = await fetch(serverHost + "api/visistpayment/updatepayment", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitPaymentDatas)
        })
        result = await result.json();
        return result;
    });
export const getAllRequest = createAsyncThunk(
    'visitrequest/getallRequestPayment',
    async () => {
        let result = await fetch(serverHost + "api/visitrequest/getallRequestPayment" , {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });


const visitPaymentSlice = createSlice({
    name: 'VisitPayment',
    initialState: {
        visitPaymentLoading: false,
        visitPayment: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllRequest.pending, (state) => {
                state.visitPaymentLoading = true;
                state.visitPayment = null;
                state.error = null;
            })
            .addCase(getAllRequest.fulfilled, (state, action) => {
                state.visitPaymentLoading = false;
                state.visitPayment = action.payload;
                state.error = null;
            })
            .addCase(getAllRequest.rejected, (state, action) => {
                state.visitPaymentLoading = false;
                state.visitPayment = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllPayment.pending, (state) => {
                state.visitPaymentLoading = true;
                state.visitPayment = null;
                state.error = null;
            })
            .addCase(getAllPayment.fulfilled, (state, action) => {
                state.visitPaymentLoading = false;
                state.visitPayment = action.payload;
                state.error = null;
            })
            .addCase(getAllPayment.rejected, (state, action) => {
                state.visitPaymentLoading = false;
                state.visitPayment = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addPayment.pending, (state) => {
                state.visitPaymentLoading = true;
                state.visitPayment = null;
                state.error = null;
            })
            .addCase(addPayment.fulfilled, (state, action) => {
                state.visitPaymentLoading = false;
                state.visitPayment = action.payload;
                state.error = null;
            })
            .addCase(addPayment.rejected, (state, action) => {
                state.visitPaymentLoading = false;
                state.visitPayment = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default visitPaymentSlice.reducer;