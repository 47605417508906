import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import "../../css/notfound.css";
function PageNotFound() {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>404 Page Not Found | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <section className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="top-head">
                                <h1 className="text-center ">404</h1>
                            </div>
                            <div className="four_zero_four_bg">



                            </div>

                            <div className="contant_box_404">
                                <h3 className="h2">
                                    Look like you're lost
                                </h3>

                                <p>the page you are looking for not avaible!</p>

                                <Link to={"/"} className="link_404">Go to Home</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PageNotFound;