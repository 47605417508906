import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import HeaderNav from "../layout/HeaderNav";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getAllFileData } from "../../redux/adminFeature";
import SideMenu from "../layout/SideMenu";
import { serverHost } from "../../Store/config";

function FilesFolders() {
    const dispatch = useDispatch();

    const handleDownload = async (filename) => {
        try {
            const response = await fetch(serverHost + "api/admin/downloadfile/imptfiles/" + filename);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const { adminloading } = useSelector((state) => state.AdminFeature);

    const [mobNav, setMobNav] = useState(false);

    const [folders, setFolders] = useState([]);

    const [members, setMembers] = useState([]);

    const [singleFull, setSingleFullData] = useState([]);
    const [singleType, setSingleType] = useState([]);

    const refreshTable = useCallback(() => {
        dispatch(getAllFileData()).then((result) => {
            if (result.payload.error === "") {
                setFolders(result.payload.data);
                const uniqueNames = [...new Set(result.payload.data.map(obj => obj.CID))];
                setMembers(uniqueNames);
            }
        });
    }, [dispatch]);

    function getClientData(memberdata) {
        return [...new Set(folders.filter((filter) => filter.CID === memberdata).map(obj => obj.MID))];
    }

    function setFullData(data, data1) {
        setSingleFullData(folders.filter((filter) => filter.CID === data && filter.MID === data1));
        setSingleType([...new Set(folders.filter((filter) => filter.CID === data && filter.MID === data1).map(obj => obj.Type))]);
    }

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Files | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Folders" valueMobNav={mobNav} />
                <div className="right-total-body position-reative">
                    <HeaderNav navValue={["Folders", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="myRow">
                            <div className="col-md-9">
                                <div className="details-title">Members</div>
                                <div className="myRow">
                                    {
                                        (members.length !== 0) ?
                                            members.map((data, index) =>
                                                <div className="data-container" key={index}>
                                                    <div className="data-title">
                                                        {data}
                                                    </div>
                                                    {
                                                        getClientData(data).map((data2, index) =>
                                                            <div className="data-item" key={index} onClick={() => setFullData(data, data2)}>
                                                                {data2}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="col-md-3 fulldata big-panel">

                                {
                                    (singleType.length !== 0) ?
                                        <>
                                            <div className="details-title">{singleFull[0].MID}</div>
                                            {
                                                singleType.map((data, index) =>
                                                    <>
                                                        <div className="sub-data-title" key={index + "_l"}>
                                                            {data}
                                                        </div>
                                                        <div className="container-data" key={index + "_r"}>
                                                            {
                                                                singleFull.filter((filter) => filter.Type === data).map((data3, index) =>
                                                                    <div className="downloaddata" key={index} onClick={() => handleDownload(data3.path)}>File {index + 1}</div>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>

                                        : ""
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                adminloading ? <Loading Type="fourdots" /> : ""
            }
        </>
    )
}
export default FilesFolders;