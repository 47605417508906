import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import HeaderNav from "../layout/HeaderNav";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";

import SideMenu from "../layout/SideMenu";
import { addRelation, deleteRelation, getAllRelation, updateRelation } from "../../redux/adminFeature";

function Relationship() {

    const dispatch = useDispatch();

    const { adminloading } = useSelector((state) => state.AdminFeature);

    const [mobNav, setMobNav] = useState(false);

    const [relationshipData, setRelationship] = useState([]);
    const [rid, setDatId] = useState("0");
    const [relation, setRelation] = useState("");
    const [addEdit, setButt] = useState(true);


    const refreshTable = useCallback(() => {
        dispatch(getAllRelation()).then((result) => {
            if (result.payload.error === "") {
                setRelationship(result.payload.data);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);


    function updateRelationship(rid, index) {
        if (rid === relationshipData[index].id) {
            setRelation(relationshipData[index].relation);
            setDatId(relationshipData[index].id);
            setButt(false);
        }

    }
    function deleteRelationship(rid) {
        dispatch(deleteRelation(rid)).then((result) => {
            if (result.payload.error === "") {
                refreshTable();
            }
        });
    }

    function addRelationship(event) {
        event.preventDefault();
        if (rid === "0") {
            let myData = { relation };
            dispatch(addRelation(myData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        } else {
            let myData = { rid, relation };
            dispatch(updateRelation(myData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        }
        document.getElementById("relationshipData").reset();
        setRelation("");
        setDatId("0");
        setButt(true);
    };

    function dataReset() {
        document.getElementById("relationshipData").reset();
        setRelation("");
        setDatId("0");
        setButt(true);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Relationship | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Reference Data" valueMobNav={mobNav} />
                <div className="right-total-body position-reative">
                    <HeaderNav navValue={["Reference Data","Relationship", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <button type="button" className="my-button bg-yellow" onClick={() => dataReset()}><i className="fas fa-undo"></i> Reset Data</button>
                        </div>

                        <div className="myRow">
                            <div className="col-md-3 spc-mob-form">
                                <div className="details-title">Add Relationship</div>
                                <form id="relationshipData" onSubmit={(event) => addRelationship(event)} className="myRow">
                                    <input type="hidden" defaultValue={rid} />
                                    <div className="input-formgroup">
                                        <label className="input-label">Relationship</label>
                                        <input className="input-cell" required="required" type="text" defaultValue={relation} placeholder="Enter Relationship" onChange={(e) => setRelation(e.target.value)} />
                                    </div>
                                    <div className="text-cont">
                                        {
                                            (addEdit) ?
                                                <button type="submit" className="my-button bg-lightgreen"><i className="fas fa-plus"></i> Add Data</button>
                                                :
                                                <button type="submit" className="my-button bg-spblue"><i className="fas fa-pencil-alt"></i> Edit Data</button>
                                        }
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-9">
                                <div className="details-title">All Relationship List</div>
                                <div className="details-table">
                                    <table className="table_inner"><thead className="my_head">
                                        <tr>
                                            <td className="normal_td table_blue mob-td-minwidth75">Action</td>
                                            <td className="normal_td table_blue">Relationship</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Created Date</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Updated Date</td>
                                        </tr>
                                    </thead>
                                        <tbody className="my_body">
                                            {
                                                (relationshipData.length !== 0) ?
                                                    relationshipData.map((relation, index) =>
                                                        <tr key={index}>
                                                            <td className="icon-tab">
                                                                <i className="fas fa-pencil-alt" onClick={() => updateRelationship(relation.id, index)}></i>
                                                                <i className="far fa-trash-alt" onClick={() => deleteRelationship(relation.id)}></i>
                                                            </td>
                                                            <td>{relation.relation}</td>
                                                            <td className="cell-w768-nodisplay">{relation.created_at.split("T")[0]}</td>
                                                            <td className="cell-w768-nodisplay">{relation.updated_at.split("T")[0]}</td>
                                                        </tr>
                                                    ) :
                                                    <tr className="no-contact-data">
                                                        <td colSpan={6} className="errordata">No Data Found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {
                adminloading ? <Loading Type="dna" /> : ""
            }
        </>
    )
}
export default Relationship;
