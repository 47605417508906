import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../formfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { addPayment, getAllRequest } from "../../redux/visitPayment";

function AddPayment() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { visitPaymentLoading } = useSelector((state) => state.VisitPayment);
    const [mobNav, setMobNav] = useState(false);

    const [vrid, setVisitID] = useState("");
    const [invid, setInvoiceID] = useState("");
    const [invamount, setInvoiceAmount] = useState("");
    const [invdate, setInvoiceDate] = useState("");
    const [paysta, setPaymentStatus] = useState("");
    const [paydate, setPaymentDate] = useState("");

    const [dummyvrid, setDummyVisitID] = useState("");
    const [dummydata, setDummyData] = useState("");

    let [visitdata, setVisitData] = useState([]);

    function addPayments(event) {
        event.preventDefault();
        let createid = JSON.parse(localStorage.getItem('member-info')).member_id;
        let memberData = { vrid, invid, invamount, invdate, paysta, paydate, createid };
        dispatch(addPayment(memberData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/visit/payment");
            }
        });
    }

    function setVirt(data) {
        setDummyVisitID(data);
        if (data !== "") {
            setVisitID(visitdata[data].vrid);
            setDummyData(visitdata[data]);
        } else {
            setVisitID("");
            setDummyData("");
        }
    }

    const getVisitDtas = useCallback(() => {
        dispatch(getAllRequest()).then((result) => {
            if (result.payload.error === "") {
                setVisitData(result.payload.data);
            }
        });
    }, [dispatch]);


    useEffect(() => {
        getVisitDtas();
    }, [getVisitDtas]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Add Payment Details | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Payments" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Payments", "Add"]} />
                    <div className="fulldetailsbody">
                        <form onSubmit={(event) => addPayments(event)}>
                            <div className="toolbar-container">
                                <Link to="/visit/payment" className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                                <button type="submit" className="my-button bg-spblue"><i className="fas fa-user-plus"></i> Add New</button>
                            </div>
                            <div className="myRow">
                                <div className="col-md-9">
                                    <div className="tab-container">
                                        <div className="tab selected">Payment Details</div>
                                    </div>
                                    <div className="details-formcontainer">
                                        <div className="tab-details selected-tab">
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Visit Request ID</label>
                                                        <select className="input-cell" onChange={(e) => setVirt(e.target.value)} required value={dummyvrid}>
                                                            <option value="" disabled="">Select Request ID</option>
                                                            {
                                                                (visitdata.length !== 0) ?
                                                                    visitdata.map((visit, index) =>
                                                                        <option key={index} value={index}>{visit.vrid}</option>
                                                                    ) :
                                                                    ""
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Schedule ID</label>
                                                        <input className="input-cell" type="text" readOnly defaultValue={(dummydata !== "") ? dummydata.vsid : ""} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Report ID</label>
                                                        <input className="input-cell" type="text" readOnly defaultValue={(dummydata !== "") ? dummydata.vreid : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Invoice ID</label>
                                                        <input className="input-cell" type="text" placeholder="Enter Invoice ID" onChange={(e) => setInvoiceID(e.target.value)} defaultValue={invid} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Invoice Date</label>
                                                        <input className="input-cell" required="required" type="datetime-local" placeholder="Enter Invoice Date" onChange={(e) => setInvoiceDate(e.target.value)} defaultValue={invdate} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Invoice Amount</label>
                                                        <input className="input-cell" required="required" type="number" placeholder="Enter Invoice Amount" onChange={(e) => setInvoiceAmount(e.target.value)} defaultValue={invamount} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Payment Status</label>
                                                        <input className="input-cell" type="text" placeholder="Enter Payment Status" onChange={(e) => setPaymentStatus(e.target.value)} defaultValue={paysta} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Payment Date</label>
                                                        <input className="input-cell" required="required" type="datetime-local" placeholder="Enter Payment Date" onChange={(e) => setPaymentDate(e.target.value)} defaultValue={paydate} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 small-padding-r">
                                    <div className="mandetory-cont big-panel">
                                        <div className="title">Mandatory Information</div>
                                        {(vrid === "") ? <div className="alert alert-danger" role="alert">Need to Select Visit Request ID</div> : ""}
                                        {(invid === "") ? <div className="alert alert-danger" role="alert">Need to fill Invoice ID</div> : ""}
                                        {(invdate === "") ? <div className="alert alert-danger" role="alert">Need to fill Invoice Date</div> : ""}
                                        {(invamount === "") ? <div className="alert alert-danger" role="alert">Need to fill Invoice Amount</div> : ""}
                                        {(paysta === "") ? <div className="alert alert-danger" role="alert">Need to fill Payment Status</div> : ""}
                                        {(paydate === "") ? <div className="alert alert-danger" role="alert">Need to fill Payment Date</div> : ""}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            {
                visitPaymentLoading ? <Loading Type="fourdots" /> : ""
            }
        </>
    )
}
export default AddPayment
