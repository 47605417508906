import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../formfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import men_member from '../../img/customer-men.png';
import women_member from '../../img/customer-women.png';
import Loading from "../Loading";
import { editClient, getclientdetails } from "../../redux/clientDetails";
import { getAllPreferedCare } from "../../redux/adminFeature";

function EditClient() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { clientid } = useParams();
    const { clientloading } = useSelector((state) => state.clientDetails);
    const [tab, setTab] = useState(1);
    const [mobNav,setMobNav]=useState(false);
    const [prefredcare, setPreferedCare] = useState([]);

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [status, setStatus] = useState("");
    const [addressFL, setAddressFL] = useState("");
    const [addressSL, setAddressSL] = useState("");
    const [relation, setRelation] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [locality, setLocality] = useState("");
    const [AadharCard, setAadharCard] = useState("");
    const [navigationmarker, setNavigationMarker] = useState("");
    const [prferedhomedata, setPrferedHome] = useState("No Preferred");
    const [agerange, setAgeRange] = useState("");
    const [medicalcard, setMedicalCard] = useState("");
    const [otherpre, setOtherPre] = useState("");
    

    function editClientForm(event) {
        event.preventDefault();
        let prferedhome = prferedhomedata;
        if (prferedhome === "Others") {
            prferedhome = prferedhome + " - " + otherpre;
        }
        let clientData = {clientid, status, gender, email, lastName, middleName, firstName, addressFL, addressSL, city, state, country, zipcode, locality, relation, AadharCard, navigationmarker, prferedhome, agerange, medicalcard };
        dispatch(editClient(clientData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/clients/view/" + clientid);
            }
        });
    }

    const getPrferedCare = useCallback(() => {
        dispatch(getAllPreferedCare()).then((result) => {
            if (result.payload.error === "") {
                setPreferedCare(result.payload.data);
            }

        });
    }, [dispatch]);

    useEffect(() => {        
        dispatch(getclientdetails(clientid)).then((result) => {
            if (result.payload.error === "") {
                let clientdata = result.payload.data;                
                setFirstName(clientdata.fname);
                setLastName(clientdata.lname);
                setMiddleName(clientdata.mname);
                setEmail(clientdata.email);
                setAddressFL(clientdata.fladdress);
                setAddressSL(clientdata.sladdress);
                setLocality(clientdata.locality);
                setCity(clientdata.city);
                setCountry(clientdata.country);
                setState(clientdata.state);
                setZipcode(clientdata.zipcode);
                setGender(clientdata.gender);
                setAadharCard(clientdata.aadhar);
                setNavigationMarker(clientdata.navigationmarker);
                setAgeRange(clientdata.agerange);
                setRelation(clientdata.relation);
                setMedicalCard(clientdata.medicalcard);
                setStatus(clientdata.status);
                if (clientdata.prferedhome.split(" - ").length > 1) {
                    if (clientdata.prferedhome.split(" - ")[0] === "Others") {
                        setPrferedHome("Others");
                        setOtherPre(clientdata.prferedhome.split(" - ")[1]);
                    } else {
                        setPrferedHome(clientdata.prferedhome);
                    }
                }
                else {
                    setPrferedHome(clientdata.prferedhome);
                }
            }
        });
        getPrferedCare();
    }, [dispatch,clientid,getPrferedCare]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Member - {clientid} | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Members" valueMobNav={mobNav}/>
                <div className="right-total-body">
                    <HeaderNav navValue={["Members", "Edit", clientid]} />
                    <div className="fulldetailsbody">
                        <form onSubmit={(event) => editClientForm(event)}>
                            <div className="toolbar-container">
                                <Link to={"/clients/view/" + clientid} className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                                <button type="submit" className="my-button bg-spblue"><i className="far fa-edit"></i> Save</button>
                            </div>
                            <div className="myRow">
                            <div className="col-md-9">
                                    <div className="tab-container">
                                        <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Basic Details</div>
                                        <div className={(tab === 2) ? "tab selected" : "tab"} onClick={() => setTab(2)}>Residential Details</div>
                                    </div>
                                    <div className="details-formcontainer">
                                        <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">First Name</label>
                                                        <input className="input-cell" defaultValue={firstName} required="required" type="text" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Middle Name</label>
                                                        <input className="input-cell" defaultValue={middleName} type="text" placeholder="Enter Middle Name" onChange={(e) => setMiddleName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Last Name</label>
                                                        <input className="input-cell" defaultValue={lastName} required="required" type="text" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="profile-pic-cont">
                                                        <img src={gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-upimg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Gender</label>
                                                                <select className="input-cell" onChange={(e) => setGender(e.target.value)} required value={gender}>
                                                                    <option value="" disabled="">Select any one</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                    <option value="others">Others</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Status</label>
                                                                <select className="input-cell" onChange={(e) => setStatus(e.target.value)} required value={status}>
                                                                    <option value="" disabled="">Select any one</option>
                                                                    <option value="active">Active</option>
                                                                    <option value="not active">Not Active</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Age Range</label>
                                                                <select className="input-cell" onChange={(e) => setAgeRange(e.target.value)} required value={agerange}>
                                                                    <option value="" disabled="">Select any one</option>
                                                                    <option value="10-20">10-20</option>
                                                                    <option value="21-30">21-30</option>
                                                                    <option value="31-40">31-40</option>
                                                                    <option value="41-50">41-50</option>
                                                                    <option value="51-60">51-60</option>
                                                                    <option value="61-70">61-70</option>
                                                                    <option value="71-80">71-80</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Preferred Home or Hospital</label>
                                                                <select className="input-cell" onChange={(e) => setPrferedHome(e.target.value)} value={prferedhomedata}>
                                                                    <option value="No Preferred">No Preferred</option>
                                                                    {
                                                                        (prefredcare.length !== 0) ?
                                                                            prefredcare.map((prefer, index) =>
                                                                                <option key={index} value={prefer.cname}>{prefer.cname}</option>
                                                                            ) :
                                                                            ""
                                                                    }
                                                                    <option value="Others">Others</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="myRow">
                                                        {
                                                            (prferedhomedata === "Others") ?
                                                                <div className="col-md-12">
                                                                    <div className="input-formgroup">
                                                                        <label className="input-label">Others Preferred Home or Hospital</label>
                                                                        <input className="input-cell" defaultValue={otherpre} required="required" type="text" placeholder="Enter Preferred Home or Hospital" onChange={(e) => setOtherPre(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={(tab === 2) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Country</label>
                                                        <input className="input-cell" defaultValue={country} required="required" type="text" placeholder="Enter Country" onChange={(e) => setCountry(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">State</label>
                                                        <input className="input-cell" defaultValue={state} required="required" type="text" placeholder="Enter State" onChange={(e) => setState(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">City</label>
                                                        <input className="input-cell" defaultValue={city} required="required" type="text" placeholder="Enter City" onChange={(e) => setCity(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Address First Line</label>
                                                        <input className="input-cell" defaultValue={addressFL} required="required" type="text" placeholder="Enter Address First Line" onChange={(e) => setAddressFL(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Address Second Line</label>
                                                        <input className="input-cell" defaultValue={addressSL} type="text" placeholder="Enter Address Second Line" onChange={(e) => setAddressSL(e.target.value)} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Locality</label>
                                                        <input className="input-cell" defaultValue={locality} type="text" placeholder="Enter Locality" onChange={(e) => setLocality(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Zipcode</label>
                                                        <input className="input-cell" defaultValue={zipcode} required="required" type="text" placeholder="Enter Zipcode" onChange={(e) => setZipcode(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Navigation Marker</label>
                                                        <input className="input-cell" defaultValue={navigationmarker} type="text" placeholder="Enter Navigation Marker" onChange={(e) => setNavigationMarker(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>                                        
                                    </div>
                                </div>
                                <div className="col-md-3 small-padding-r">
                                <div className="mandetory-cont big-panel">
                                        <div className="title">Mandatory Information</div>
                                        {(firstName === "") ? <div className="alert alert-danger" role="alert">Need to fill First Name in Basic Details tab</div> : ""}
                                        {(lastName === "") ? <div className="alert alert-danger" role="alert">Need to fill Last Name in Basic Details tab</div> : ""}
                                        {(email === "") ? <div className="alert alert-danger" role="alert">Need to fill Email in Basic Details tab</div> : ""}                                        
                                        {(gender === "") ? <div className="alert alert-danger" role="alert">Need to fill Gender in Basic Details tab</div> : ""}
                                        {(status === "") ? <div className="alert alert-danger" role="alert">Need to fill Status in Basic Details tab</div> : ""}
                                        {(agerange === "") ? <div className="alert alert-danger" role="alert">Need to fill Age Range in Basic Details tab</div> : ""}
                                        {(country === "") ? <div className="alert alert-danger" role="alert">Need to fill Country in Residential Details tab</div> : ""}
                                        {(state === "") ? <div className="alert alert-danger" role="alert">Need to fill State in Residential Details tab</div> : ""}
                                        {(city === "") ? <div className="alert alert-danger" role="alert">Need to fill City in Residential Details tab</div> : ""}
                                        {(addressFL === "") ? <div className="alert alert-danger" role="alert">Need to fill Address First Line in Residential Details tab</div> : ""}
                                        {(zipcode === "") ? <div className="alert alert-danger" role="alert">Need to fill Zipcode in Residential Details tab</div> : ""}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            {
                clientloading ? <Loading Type="fourdots" /> : ""
            }
        </>
    )
}
export default EditClient;
