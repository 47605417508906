import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './component/Login/Login';
import ProtectedMember from './component/ProtectedMember';
import Dashboard from './component/Dashboard/Dashboard';
import Members from './component/Members/Members';
import AddMember from './component/Members/AddMember';
import ViewMember from './component/Members/ViewMember';
import EditMember from './component/Members/EditMember';
import Profile from './component/Profile/Profile';
import EditProfile from './component/Profile/EditProfile';
import ChangePassword from './component/Profile/ChangePassword';
import Customers from './component/Customers/Customers';
import AddCustomer from './component/Customers/AddCustomer';
import ViewCustomer from './component/Customers/ViewCustomer';
import EditCustomer from './component/Customers/EditCustomer';
import Clients from './component/Clients/Clients';
import AddClient from './component/Clients/AddClient';
import ViewClient from './component/Clients/ViewClient';
import EditClient from './component/Clients/EditClient';
import VisitSchedule from './component/VisitSchedule/VisitSchedule';
import VisitListSchedule from './component/VisitSchedule/VisitListSchedule';
import VisitListRequest from './component/VisitSchedule/VisitListRequest';
import ViewVisitRequest from './component/VisitSchedule/ViewVisitRequest';
import PageNotFound from './component/layout/PageNotFoun';
import NewVisitSchedule from './component/VisitSchedule/NewVisitSchedule';
import UpdateVisitSchedule from './component/VisitSchedule/UpdateVisitSchedule';
import VisitReport from './component/VisitReport/VisitReport';
import UpcomingVisit from './component/VisitReport/UpcomingVisit';
import VisitListReport from './component/VisitReport/VisitListReport';
import ViewVisitReport from './component/VisitReport/ViewVisitReport';
import NewVisitReport from './component/VisitReport/NewVisitReport';
import UpdateVisitReport from './component/VisitReport/UpdateVisitReport';
import PreferedData from './component/Admin/PreferedData';
import TaskStatus from './component/Admin/TaskStatus';
import Relationship from './component/Admin/Relationship';
import Chat from './component/Chat/Chat';
import ReferenceData from './component/Admin/ReferenceData';
import AnonymousVisitRequests from './component/Admin/AnonymousVisitRequests';
import ServiceType from './component/Admin/ServiceType';
import Payments from './component/Payment/Payments';
import AddPayment from './component/Payment/AddPayment';
import FilesFolders from './component/Admin/FilesFolders';
import FileUpload from './component/Clients/FileUpload';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/dashboard' element={<ProtectedMember components={Dashboard}/>}/>
          <Route path='/employees' element={<ProtectedMember components={Members}/>}/>
          <Route path='/employees/add' element={<ProtectedMember components={AddMember}/>}/>
          <Route path='/employees/view/:memberid' element={<ProtectedMember components={ViewMember}/>} />
          <Route path='/employees/edit/:memberid' element={<ProtectedMember components={EditMember}/>} />
          <Route path='/profile' element={<ProtectedMember components={Profile}/>}/>
          <Route path='/profile/edit' element={<ProtectedMember components={EditProfile}/>}/>
          <Route path='/profile/password' element={<ProtectedMember components={ChangePassword}/>}/>
          <Route path='/customers' element={<ProtectedMember components={Customers}/>}/>
          <Route path='/customers/add' element={<ProtectedMember components={AddCustomer}/>}/>
          <Route path='/customers/view/:customerid' element={<ProtectedMember components={ViewCustomer}/>} />
          <Route path='/customers/edit/:customerid' element={<ProtectedMember components={EditCustomer}/>} />
          <Route path='/clients' element={<ProtectedMember components={Clients}/>}/>
          <Route path='/clients/add' element={<ProtectedMember components={AddClient}/>}/>
          <Route path='/clients/view/:clientid' element={<ProtectedMember components={ViewClient}/>} />
          <Route path='/clients/edit/:clientid' element={<ProtectedMember components={EditClient}/>} />
          <Route path='/clients/files/:clientid/:cusid' element={<ProtectedMember components={FileUpload}/>} />
          <Route path='/visit/schedule' element={<ProtectedMember components={VisitSchedule}/>}/>
          <Route path='/visit/schedule/:visitType' element={<ProtectedMember components={VisitListSchedule}/>}/>
          <Route path='/visit/request' element={<ProtectedMember components={VisitListRequest}/>}/>
          <Route path='/visit/request/view/:requestId' element={<ProtectedMember components={ViewVisitRequest}/>}/>
          <Route path='/visit/schedule/new/:requestId' element={<ProtectedMember components={NewVisitSchedule}/>}/>
          <Route path='/visit/schedule/edit/:visitScheduleId' element={<ProtectedMember components={UpdateVisitSchedule}/>}/>
          <Route path='/visit/report' element={<ProtectedMember components={VisitReport}/>}/>
          <Route path='/visit/upcoming' element={<ProtectedMember components={UpcomingVisit}/>}/>
          <Route path='/visit/report/:visitType' element={<ProtectedMember components={VisitListReport}/>}/>
          <Route path='/visit/report/view/:requestId' element={<ProtectedMember components={ViewVisitReport}/>}/>
          <Route path='/visit/report/new/:requestId' element={<ProtectedMember components={NewVisitReport}/>}/>
          <Route path='/visit/report/edit/:reportId' element={<ProtectedMember components={UpdateVisitReport}/>}/>
          <Route path='/admin/home' element={<ProtectedMember components={PreferedData}/>}/>
          <Route path='/admin/taskstatus' element={<ProtectedMember components={TaskStatus}/>}/>
          <Route path='/admin/relationship' element={<ProtectedMember components={Relationship}/>}/>
          <Route path='/admin/referencedata' element={<ProtectedMember components={ReferenceData}/>}/>
          <Route path='/admin/service' element={<ProtectedMember components={ServiceType}/>}/>
          <Route path='/admin/folders' element={<ProtectedMember components={FilesFolders}/>}/>
          <Route path='/chat' element={<ProtectedMember components={Chat}/>}/>
          <Route path='/visit/anonymous' element={<ProtectedMember components={AnonymousVisitRequests}/>}/>
          <Route path='/visit/payment' element={<ProtectedMember components={Payments}/>}/>
          <Route path='/visit/payment/new' element={<ProtectedMember components={AddPayment}/>}/>

          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
