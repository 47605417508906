import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import men_member from '../../img/client-men.png';
import women_member from '../../img/client-women.png';
import Loading from "../Loading";
import { addCustomerContact, deleteCustomerContact, getCustomerContacts, getCustomerDetails, resetCustomerPassword, updateCustomerContact } from "../../redux/customerDetails";

function ViewCustomer() {

    const dispatch = useDispatch();
    let { customerid } = useParams();
    const { loading } = useSelector((state) => state.customerDetails);

    const [tab, setTab] = useState(1);
    const [models, setModel] = useState(false);
    const [mobNav, setMobNav] = useState(false);
    const [customerDetails, setCustomerDetails] = useState([]);

    const [contacts, setContacts] = useState([]);
    const [contact_id, setContactId] = useState("0");
    const [contact_type, setContactType] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [editCon, setEditCon] = useState(<button type="submit" className="my-button bg-lightblue"><i className="fas fa-plus"></i> Add</button>);


    const refreshTable = useCallback(() => {
        dispatch(getCustomerContacts(customerDetails.customer_id)).then((result) => {
            if (result.payload.error === "") {
                setContacts(result.payload.data);
            }
        });
    }, [dispatch, customerDetails.customer_id]);

    useEffect(() => {
        dispatch(getCustomerDetails(customerid)).then((result) => {
            if (result.payload.error === "") {
                let memberdata = result.payload.data;
                setCustomerDetails(memberdata);
            }
        });
        refreshTable();
    }, [dispatch, customerid, refreshTable]);


    function updateEditContact(contact_id, index) {
        if (contact_id === contacts[index].id) {
            setEditCon(<button type="submit" className="my-button bg-deeporange"><i className="fas fa-pen"></i> Edit</button>);
            setContactType(contacts[index].contact_type);
            setPhone(contacts[index].phone);
            setEmail(contacts[index].email);
            setWhatsapp(contacts[index].whatsapp);
            setContactId(contacts[index].id);
        }

    }
    function deleteContact(contact_id) {
        dispatch(deleteCustomerContact(contact_id)).then((result) => {
            if (result.payload.error === "") {
                refreshTable();
            }
        });
    }

    function addContact(event) {
        event.preventDefault();
        let cid = customerDetails.customer_id;
        if (contact_id === "0") {
            let contactData = { contact_type, phone, email, whatsapp };
            dispatch(addCustomerContact({ cid, contactData })).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        } else {
            let contactData = { contact_id, contact_type, phone, email, whatsapp };
            dispatch(updateCustomerContact(contactData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        }
        document.getElementById("ContactMember").reset();
        setEditCon(<button type="submit" className="my-button bg-lightblue"><i className="fas fa-plus"></i> Add</button>);
        setContactType("");
        setPhone("");
        setEmail("");
        setWhatsapp("");
        setContactId("0");

    };

    function resetPassword() {
        dispatch(resetCustomerPassword(customerid)).then((result) => {
            if (result.payload.error === "") {
                if (result.payload.data === "Password Change Sucessfully") {
                    setModel(true);
                }
            }
        });
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View Customer - {customerid} | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Customers" valueMobNav={mobNav} />
                <div className="right-total-body position-reative">
                    <HeaderNav navValue={["Customers", "View", customerid]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to="/customers" className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                            <Link to={"/customers/edit/" + customerid} className="my-button bg-lightgreen"><i className="fas fa-user-edit"></i> Edit Customer</Link>
                            <div className="my-button bg-lightred" onClick={() => resetPassword()}><i className="fas fa-unlock"></i> Reset Password</div>
                        </div>
                        <div className="myRow">
                            <div className="full-mod-tab">
                                <div className="tab-container">
                                    <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Basic Details</div>
                                    <div className={(tab === 2) ? "tab selected" : "tab"} onClick={() => setTab(2)}>Residential Details</div>
                                    <div className={(tab === 3) ? "tab selected" : "tab"} onClick={() => setTab(3)}>Contact Details</div>
                                    <div className={(tab === 4) ? "tab selected" : "tab"} onClick={() => setTab(4)}>Company Details</div>
                                </div>
                            </div>
                            <div className="details-formcontainer">
                                <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>
                                    <div className="myRow">
                                        <div className="col-md-3">
                                            <div className="profile-img-cont">
                                                <img src={customerDetails.gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-deimg" />
                                            </div>
                                            <div className="text-cont">
                                                <div className="member-id-text">{customerid}</div>
                                                <div className="member-role-text"><span className="member-status-text">{customerDetails.status}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="title-label">Name</div>
                                                    <div className="details-text">{customerDetails.fname} {customerDetails.mname} {customerDetails.lname}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Gender</div>
                                                    <div className="details-text startwordcapital">{customerDetails.gender}</div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-12">
                                                    <div className="title-label">Email</div>
                                                    <div className="details-text">{customerDetails.email}</div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="title-label">Last Updated Date</div>
                                                    <div className="details-text">{(customerDetails.updated_at !== undefined) ? customerDetails.updated_at.split("T")[0] : ""}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Created Date</div>
                                                    <div className="details-text">{(customerDetails.created_at !== undefined) ? customerDetails.created_at.split("T")[0] : ""}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(tab === 2) ? "tab-details selected-tab" : "tab-details"}>
                                    <div className="myRow padding-l-med">
                                        <div className="col-md-8">
                                            <div className="title-label">Address First Line</div>
                                            <div className="details-text">{customerDetails.fladdress}</div>
                                        </div>
                                    </div>
                                    {
                                        (customerDetails.sladdress !== null) ? <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="title-label">Address Second Line</div>
                                                <div className="details-text">{customerDetails.sladdress}</div>
                                            </div>
                                        </div> : ""
                                    }
                                    <div className="myRow padding-l-med">
                                        <div className="col-md-4">
                                            <div className="title-label">Country</div>
                                            <div className="details-text">{customerDetails.country}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="title-label">State</div>
                                            <div className="details-text">{customerDetails.state}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="title-label">City</div>
                                            <div className="details-text">{customerDetails.city}</div>
                                        </div>
                                    </div>
                                    <div className="myRow padding-l-med">
                                        <div className="col-md-4">
                                            <div className="title-label">Locality</div>
                                            <div className="details-text">{customerDetails.locality}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="title-label">Zipcode</div>
                                            <div className="details-text">{customerDetails.zipcode}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="title-label">Time Zone</div>
                                            <div className="details-text">{customerDetails.timezone}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(tab === 3) ? "tab-details selected-tab" : "tab-details"}>
                                    <div className="myRow">
                                        <div className="col-md-9">
                                            <div className="myRow">
                                                {
                                                    (contacts.length !== 0) ?
                                                        contacts.map((contact, index) =>
                                                            <div className="col-md-3 contact-con" key={contact.id}>
                                                                <div className={(contact.contact_type === "Emergency") ? "profilecontact-card profilecontact-emergency" : "profilecontact-card profilecontact-regular"} >
                                                                    <div className="profilecontact-type">
                                                                        <i className="far fa-pen" title="Edit Contact" onClick={(e) => updateEditContact(contact.id, index)}></i>
                                                                        <span className="head-text">{contact.contact_type}</span>
                                                                        <i className="fas fa-trash" title="Delete Contact" onClick={(e) => deleteContact(contact.id)}></i>
                                                                    </div>
                                                                    <div className="profilecontact-phone"><div className="label">Phone</div><i className="fas fa-phone-alt"></i> {contact.phone}</div>
                                                                    <div className="profilecontact-email" data-toggle="tooltip" data-placement="bottom" title={contact.email}><div className="label">Email</div><i className="fas fa-at"></i> {contact.email}</div>
                                                                    <div className="profilecontact-whatsapp"><div className="label">WhatsApp</div><i className="fab fa-whatsapp"></i> {contact.whatsapp}</div>
                                                                </div>
                                                            </div>
                                                        ) : <div className="no-contact-data">No Contact Added</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <form id="ContactMember" onSubmit={(event) => addContact(event)} className="myRow">
                                                <input type="hidden" defaultValue={contact_id} id="contactid" />
                                                <div className="input-formgroup">
                                                    <label className="input-label">Contact Type</label>
                                                    <select className="input-cell" onChange={(e) => setContactType(e.target.value)} required value={contact_type}>
                                                        <option value="" disabled >Select any one</option>
                                                        <option value="Regular">Regular</option>
                                                        <option value="Emergency">Emergency</option>
                                                    </select>
                                                </div>
                                                <div className="input-formgroup">
                                                    <label className="input-label">Email</label>
                                                    <input className="input-cell" required="required" type="email" defaultValue={email} placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                                <div className="input-formgroup">
                                                    <label className="input-label">Mobile Number</label>
                                                    <input className="input-cell" required="required" type="text" defaultValue={phone} placeholder="Enter Mobile Number" onChange={(e) => setPhone(e.target.value)} />
                                                </div>
                                                <div className="input-formgroup">
                                                    <label className="input-label">Whatsapp Number</label>
                                                    <input className="input-cell" required="required" type="text" defaultValue={whatsapp} placeholder="Enter Whatsapp Number" onChange={(e) => setWhatsapp(e.target.value)} />
                                                </div>
                                                <div className="text-cont">
                                                    {editCon}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className={(tab === 4) ? "tab-details selected-tab" : "tab-details"}>
                                    <div className="myRow padding-l-med">
                                        <div className="col-md-4">
                                            <div className="title-label">Customer ID</div>
                                            <div className="details-text">{customerid}</div>
                                        </div>
                                    </div>
                                    <div className="myRow padding-l-med">
                                        <div className="col-md-4">
                                            <div className="title-label">Plan Type</div>
                                            <div className="details-text">{(customerDetails.plantype === null) ? "N/A" : customerDetails.plantype}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="title-label">Visits Left</div>
                                            <div className="details-text">{(customerDetails.visitsleft === null) ? "N/A" : customerDetails.visitsleft}</div>
                                        </div>
                                    </div>
                                    <div className="myRow padding-l-med">
                                        <div className="col-md-4">
                                            <div className="title-label">Start Date</div>
                                            <div className="details-text">{(customerDetails.sincedate === null) ? "N/A" : customerDetails.sincedate}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="title-label">Expiration date</div>
                                            <div className="details-text">{(customerDetails.expirationdate === null) ? "N/A" : customerDetails.expirationdate}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={(models === true) ? "mymodel modelfade modelshow" : "mymodel modelfade"}>
                        <div className="mymodal-dialog">
                            <div className="mymodal-content">
                                <div className="mymodal-header">
                                    Information
                                </div>
                                <div className="mymodal-body">
                                    Password changed. New Password is "Password".
                                </div>
                                <div className="mymodal-footer">
                                    <div className="my-button bg-spblue" onClick={() => setModel(false)}> OK </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                loading ? <Loading Type="fourdots" /> : ""
            }
        </>
    )
}
export default ViewCustomer;
