import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import HeaderNav from "../layout/HeaderNav";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";

import SideMenu from "../layout/SideMenu";
import { addTaskSta, deleteTaskSta, getAllTaskSta, updateTaskSta } from "../../redux/adminFeature";

function TaskStatus() {

    const dispatch = useDispatch();

    const { adminloading } = useSelector((state) => state.AdminFeature);

    const [mobNav, setMobNav] = useState(false);

    const [taskStatusData, setTaskStatus] = useState([]);
    const [tsid, setDatId] = useState("0");
    const [taskname, setTaskname] = useState("");
    const [persentage, setPersentage] = useState(0);
    const [addEdit, setButt] = useState(true);


    const refreshTable = useCallback(() => {
        dispatch(getAllTaskSta()).then((result) => {
            if (result.payload.error === "") {
                setTaskStatus(result.payload.data);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);


    function updateTaskStatus(tsid, index) {
        if (tsid === taskStatusData[index].id) {
            setTaskname(taskStatusData[index].taskname);
            setPersentage(taskStatusData[index].persentage);
            setDatId(taskStatusData[index].id);
            setButt(false);
        }

    }
    function deleteTaskStatus(tsid) {
        dispatch(deleteTaskSta(tsid)).then((result) => {
            if (result.payload.error === "") {
                refreshTable();
            }
        });
    }

    function addTaskStatus(event) {
        event.preventDefault();
        if (tsid === "0") {
            let myData = { taskname, persentage };
            dispatch(addTaskSta(myData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        } else {
            let myData = { tsid, taskname, persentage };
            dispatch(updateTaskSta(myData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        }
        document.getElementById("taskStatusData").reset();
        setTaskname("");
        setPersentage(0);
        setDatId("0");
        setButt(true);
    };

    function dataReset() {
        document.getElementById("taskStatusData").reset();
        setTaskname("");
        setPersentage(0);
        setDatId("0");
        setButt(true);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Task Status | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Reference Data" valueMobNav={mobNav} />
                <div className="right-total-body position-reative">
                    <HeaderNav navValue={["Reference Data", "Task Status", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <button type="button" className="my-button bg-yellow" onClick={() => dataReset()}><i className="fas fa-undo"></i> Reset Data</button>
                        </div>

                        <div className="myRow">
                            <div className="col-md-3 spc-mob-form">
                                <div className="details-title">Add Task Status</div>
                                <form id="taskStatusData" onSubmit={(event) => addTaskStatus(event)} className="myRow">
                                    <input type="hidden" defaultValue={tsid} />
                                    <div className="input-formgroup">
                                        <label className="input-label">Task Status</label>
                                        <input className="input-cell" required="required" type="text" defaultValue={taskname} placeholder="Enter Task Status" onChange={(e) => setTaskname(e.target.value)} />
                                    </div>
                                    <div className="input-formgroup">
                                        <label className="input-label">Percentage of Task</label>
                                        <input className="input-cell" required="required" type="number" defaultValue={persentage} placeholder="Enter Percentage of Task" onChange={(e) => setPersentage(e.target.value)} min={0} max={100} />
                                    </div>
                                    <div className="text-cont">
                                        {
                                            (addEdit) ?
                                                <button type="submit" className="my-button bg-lightgreen"><i className="fas fa-plus"></i> Add Data</button>
                                                :
                                                <button type="submit" className="my-button bg-spblue"><i className="fas fa-pencil-alt"></i> Edit Data</button>
                                        }
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-9">
                                <div className="details-title">All Task Status List</div>
                                <div className="details-table">
                                    <table className="table_inner"><thead className="my_head">
                                        <tr>
                                            <td className="normal_td table_blue mob-td-minwidth75">Action</td>
                                            <td className="normal_td table_blue">Task Status</td>
                                            <td className="normal_td table_blue">Percentage of Task</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Created Date</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Updated Date</td>
                                        </tr>
                                    </thead>
                                        <tbody className="my_body">
                                            {
                                                (taskStatusData.length !== 0) ?
                                                    taskStatusData.map((task, index) =>
                                                        <tr key={index}>
                                                            <td className="icon-tab">
                                                                <i className="fas fa-pencil-alt" onClick={() => updateTaskStatus(task.id, index)}></i>
                                                                <i className="far fa-trash-alt" onClick={() => deleteTaskStatus(task.id)}></i>
                                                            </td>
                                                            <td>{task.taskname}</td>
                                                            <td>{task.persentage} %</td>
                                                            <td className="cell-w768-nodisplay">{task.created_at.split("T")[0]}</td>
                                                            <td className="cell-w768-nodisplay">{task.updated_at.split("T")[0]}</td>
                                                        </tr>
                                                    ) :
                                                    <tr className="no-contact-data">
                                                        <td colSpan={6} className="errordata">No Data Found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {
                adminloading ? <Loading Type="dna" /> : ""
            }
        </>
    )
}
export default TaskStatus;
