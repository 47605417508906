import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllRequest = createAsyncThunk(
    'visitrequest/getallrequest',
    async (type) => {
        let result = await fetch(serverHost + "api/company/visitrequest/" + type, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getvisitRequestdetails = createAsyncThunk(
    'visitrequest/getdetails',
    async (visitRequestId) => {
        let result = await fetch(serverHost + "api/customer/visitrequest/get/" + visitRequestId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getFullVisitDetails = createAsyncThunk(
    'visit/getfulldetails',
    async (visitRequestId) => {
        let result = await fetch(serverHost + "api/visit/getfulldetails/" + visitRequestId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const notifyMessage = createAsyncThunk(
    'visitschedulling/notify',
    async (notifydata) => {
        let result = await fetch(serverHost + "api/visitschedulling/notify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(notifydata)
        })       
        result = await result.json();
        return result;
    });

const visitRequest = createSlice({
    name: 'VisitRequest',
    initialState: {
        visitRequestLoading: false,
        visitRequest: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(notifyMessage.pending, (state) => {
                state.visitRequestLoading = true;
                state.visitRequest = null;
                state.error = null;
            })
            .addCase(notifyMessage.fulfilled, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = action.payload;
                state.error = null;
            })
            .addCase(notifyMessage.rejected, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllRequest.pending, (state) => {
                state.visitRequestLoading = true;
                state.visitRequest = null;
                state.error = null;
            })
            .addCase(getAllRequest.fulfilled, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = action.payload;
                state.error = null;
            })
            .addCase(getAllRequest.rejected, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getvisitRequestdetails.pending, (state) => {
                state.visitRequestLoading = true;
                state.visitRequest = null;
                state.error = null;
            })
            .addCase(getvisitRequestdetails.fulfilled, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = action.payload;
                state.error = null;
            })
            .addCase(getvisitRequestdetails.rejected, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getFullVisitDetails.pending, (state) => {
                state.visitRequestLoading = true;
                state.visitRequest = null;
                state.error = null;
            })
            .addCase(getFullVisitDetails.fulfilled, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = action.payload;
                state.error = null;
            })
            .addCase(getFullVisitDetails.rejected, (state, action) => {
                state.visitRequestLoading = false;
                state.visitRequest = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default visitRequest.reducer;