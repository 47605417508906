import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { useState } from "react";

function Dashboard() {

    const [mobNav, setMobNav] = useState(false);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Member's Dashboard | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Dashboard" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Dashboard"]} />
                    <div className="fulldetailsbody">
                        <div className="dashboard-text">
                            Welcome to<br/>
                            Parental Care Kolkata
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Dashboard;