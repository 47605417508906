import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import HeaderNav from "../layout/HeaderNav";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { assignAsso, getAllAnonymousJoinRequests, getAllAnonymousRequests } from "../../redux/adminFeature";
import SideMenu from "../layout/SideMenu";
import { serverHost } from "../../Store/config";
import { Link } from "react-router-dom";
import { getAllMember } from "../../redux/memberDetails";

function AnonymousVisitRequests() {

    const dispatch = useDispatch();
    const [models, setModel] = useState(false);
    const [rid, setRid] = useState();
    const [assoname, setassoname] = useState();
    const [assoesid, setassoesid] = useState();

    const handleDownload = async (filename) => {
        try {
            const response = await fetch(serverHost + "api/admin/downloadfile/joincv/" + filename);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const { adminloading } = useSelector((state) => state.AdminFeature);

    const [mobNav, setMobNav] = useState(false);

    const [aVisitRequest, setAnonymousVisitRequests] = useState([]);
    const [aJoinRequest, setAnonymousJoinRequests] = useState([]);
    const [assouser, setAssoUser] = useState([]);

    let memberuser_id = JSON.parse(localStorage.getItem('member-info')).member_id;

    const type = "associate";

    const refreshTable = useCallback(() => {
        dispatch(getAllAnonymousRequests()).then((result) => {
            if (result.payload.error === "") {
                setAnonymousVisitRequests(result.payload.data);
            }
        });
        dispatch(getAllAnonymousJoinRequests()).then((result) => {
            if (result.payload.error === "") {
                setAnonymousJoinRequests(result.payload.data);
            }
        });
        dispatch(getAllMember({ type, memberuser_id })).then((result) => {
            if (result.payload.error === "") {
                setAssoUser(result.payload.data);                
            }
        });       
    }, [dispatch]);

    function SetModelData(index) {
        setRid(aVisitRequest[index].id);
        setModel(true);
    }

    const getAsso = useCallback((event) => {
        let custo_id = event.target.value;     
        setassoesid(custo_id);
        setassoname(event.target.options[event.target.selectedIndex].text);
    }, [])

    function notifyAsso(event) {
        event.preventDefault();
        let assoid=assouser[assoesid].member_id;
        let assoemail=assouser[assoesid].email;
        let memberDetails = JSON.parse(localStorage.getItem('member-info'));
        let edata = (memberDetails.mname !== null) ? memberDetails.mname : "";
        let sendername = memberDetails.fname + " " + edata + " " + memberDetails.lname;
        let assign = { rid, assoid, sendername, assoname, assoemail };
        dispatch(assignAsso(assign)).then((result) => {
            if (result.payload.error === "") {
                refreshTable();
                setModel(false);
            }
        });
    }

    useEffect(() => {
        refreshTable();        
    }, [refreshTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Anonymous Visit Requests | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Anonymous" valueMobNav={mobNav} />
                <div className="right-total-body position-reative">
                    <HeaderNav navValue={["Visit Requests", "Anonymous"]} />
                    <div className="fulldetailsbody">

                        <div className="myRow">
                            <div className="col-md-12">
                                <div className="details-title">Anonymous Visit Requests</div>
                                <div className="details-table two-table2">
                                    <table className="table_inner"><thead className="my_head">
                                        <tr>
                                            <td className="normal_td table_blue">Assign</td>
                                            <td className="normal_td table_blue">Visit ID</td>
                                            <td className="normal_td table_blue">Details</td>
                                            <td className="normal_td table_blue">Contact Infromation</td>
                                            <td className="normal_td table_blue">Asso Name</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Request Date</td>
                                        </tr>
                                    </thead>
                                        <tbody className="my_body">
                                            {
                                                (aVisitRequest.length !== 0) ?
                                                    aVisitRequest.map((pref, index) =>
                                                        <tr key={index}>
                                                            <td className="icon-tab">
                                                                {
                                                                    (pref.assoname === "") ?
                                                                        <i className="far fa-envelope" onClick={() => SetModelData(index)}></i>
                                                                        :
                                                                        ""
                                                                }
                                                            </td>
                                                            <td>{pref.avsid}</td>
                                                            <td>{pref.information}</td>
                                                            <td>{pref.contact}</td>
                                                            <td>{pref.assoname}</td>
                                                            <td className="cell-w768-nodisplay">{pref.created_at.split("T")[0]}</td>
                                                        </tr>
                                                    ) :
                                                    <tr className="no-contact-data">
                                                        <td colSpan={6} className="errordata">No Data Found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <hr />
                        <div className="myRow">
                            <div className="col-md-12">
                                <div className="details-title">Join Requests</div>
                                <div className="details-table two-table2">
                                    <table className="table_inner"><thead className="my_head">
                                        <tr>
                                            <td className="normal_td table_blue">Name</td>
                                            <td className="normal_td table_blue">Email</td>
                                            <td className="normal_td table_blue">Contact No</td>
                                            <td className="normal_td table_blue">Address</td>
                                            <td className="normal_td table_blue">Comment</td>
                                            <td className="normal_td table_blue">CV</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Request Date</td>
                                        </tr>
                                    </thead>
                                        <tbody className="my_body">
                                            {
                                                (aJoinRequest.length !== 0) ?
                                                    aJoinRequest.map((pref, index) =>
                                                        <tr key={index}>
                                                            <td>{pref.name}</td>
                                                            <td>{pref.email}</td>
                                                            <td>{pref.mobile}</td>
                                                            <td>{pref.address}</td>
                                                            <td>{pref.comment}</td>
                                                            <td className="icon-tab ">
                                                                {(pref.cv !== "" && pref.cv !== null) ?
                                                                    <div onClick={() => handleDownload(pref.cv)}>
                                                                        <i className="fas fa-file-download"></i>
                                                                    </div> : ""}

                                                            </td>
                                                            <td className="cell-w768-nodisplay">{pref.created_at.split("T")[0]}</td>
                                                        </tr>
                                                    ) :
                                                    <tr className="no-contact-data">
                                                        <td colSpan={7} className="errordata">No Data Found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div className={(models === true) ? "mymodel modelfade modelshow" : "mymodel modelfade"}>
                <div className="mymodal-dialog">
                    <div className="mymodal-content">
                        <form onSubmit={(event) => notifyAsso(event)}>
                            <div className="mymodal-header">
                                Assign Associate
                            </div>
                            <div className="mymodal-body input-model">
                                <div className="myRow">
                                    <div className="col-md-12">
                                        <div className="input-formgroup">
                                            <label className="input-label">Select Associate</label>
                                            <select className="input-cell" onChange={(e) => getAsso(e)} required value={assoesid}>
                                                <option value="" disabled="">Select any Associate</option>
                                                {
                                                    (assouser.length !== 0) ?
                                                        assouser.map((asso, index) =>
                                                            <option key={index} value={index}>{asso.fname} {asso.mname} {asso.lname}</option>
                                                        ) :
                                                        ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mymodal-footer">
                                <button type="submit" className="my-button bg-lightgreen"> Assign </button>
                                <div className="my-button bg-lightred" onClick={() => setModel(false)}> Close </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                adminloading ? <Loading Type="fourdots" /> : ""
            }
        </>
    )
}
export default AnonymousVisitRequests;
