import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllReport = createAsyncThunk(
    'visitreport/getallreport',
    async () => {
        let result = await fetch(serverHost + "api/visitreport/getallreport", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addReport = createAsyncThunk(
    'visitreport/addnewreport',
    async (visitReportData) => {
        let result = await fetch(serverHost + "api/visitreport/addnewreport", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitReportData)
        })
        result = await result.json();
        return result;
    });
export const updateReport = createAsyncThunk(
    'visitreport/updatereport',
    async (visitReportData) => {
        let visitReportDatas = visitReportData;
        let result = await fetch(serverHost + "api/visitreport/updatereport", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitReportDatas)
        })
        result = await result.json();
        return result;
    });
export const getvisitReportdetails = createAsyncThunk(
    'visitreport/getreport',
    async (visitReportId) => {
        let result = await fetch(serverHost + "api/visitreport/getreport/" + visitReportId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getDraftReport = createAsyncThunk(
    "visitreport/getdraftreport",
    async (visitReportId) => {
        let result = await fetch(serverHost + "api/visitreport/getdraftreport/" + visitReportId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

export const getValidReport = createAsyncThunk(
    "visitreport/getvalidreport",
    async (visitReportId) => {
        let result = await fetch(serverHost + "api/visitreport/getvalidreport/" + visitReportId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const updateReportStatus = createAsyncThunk(
    "visitreport/updatereportstatus",
    async (yourData) => {
        let visitReportId = yourData.visitReportId;
        let status = yourData.status;
        let result = await fetch(serverHost + "api/visitreport/updatereportstatus/" + visitReportId + "/" + status, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getReportbyVR = createAsyncThunk(
    "visitreport/getreport/visitrequest",
    async (visitReportId) => {
        let result = await fetch(serverHost + "api/visitreport/getreport/visitrequest/" + visitReportId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getAllReportByEmp = createAsyncThunk(
    "visitreport/getreportEmp",
    async (emp_id) => {
        let result = await fetch(serverHost + "api/visitreport/getallreport/" + emp_id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const uploadFile = createAsyncThunk(
    'visitreport/uploadfile',
    async (visitReportData) => {
        let visitReportDatas = visitReportData;
        let result = await fetch(serverHost + "api/visitreport/uploadfile", {
            method: "POST",
            body: visitReportDatas
        })
        result = await result.json();
        return result;
    });
export const edituploadFile = createAsyncThunk(
    'visitreport/edituploadfile',
    async (visitReportData) => {
        let visitReportDatas = visitReportData;
        let result = await fetch(serverHost + "api/visitreport/edituploadfile", {
            method: "POST",
            body: visitReportDatas
        })
        result = await result.json();
        return result;
    });

const visitReportSlice = createSlice({
    name: 'VisitReport',
    initialState: {
        visitReportLoading: false,
        visitReport: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFile.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(edituploadFile.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(edituploadFile.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(edituploadFile.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllReport.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(getAllReport.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(getAllReport.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addReport.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(addReport.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(addReport.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getvisitReportdetails.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(getvisitReportdetails.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(getvisitReportdetails.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getValidReport.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(getValidReport.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(getValidReport.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getDraftReport.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(getDraftReport.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(getDraftReport.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getReportbyVR.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(getReportbyVR.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(getReportbyVR.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateReportStatus.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(updateReportStatus.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(updateReportStatus.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllReportByEmp.pending, (state) => {
                state.visitReportLoading = true;
                state.visitReport = null;
                state.error = null;
            })
            .addCase(getAllReportByEmp.fulfilled, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = action.payload;
                state.error = null;
            })
            .addCase(getAllReportByEmp.rejected, (state, action) => {
                state.visitReportLoading = false;
                state.visitReport = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default visitReportSlice.reducer;