import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllSchedule = createAsyncThunk(
    'visitschedule/getallschedule',
    async () => {
        let result = await fetch(serverHost + "api/visitschedulling/getallschedule", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addSchedule = createAsyncThunk(
    'visitschedulling/addnewschedule',
    async (visitScheduleData) => {
        let result = await fetch(serverHost + "api/visitschedulling/addnewschedule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitScheduleData)
        })
        result = await result.json();
        return result;
    });
export const updateSchedule = createAsyncThunk(
    'visitschedulling/updateschedule',
    async (visitScheduleData) => {
        let result = await fetch(serverHost + "api/visitschedulling/updateschedule", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitScheduleData)
        })
        result = await result.json();
        return result;
    });
export const getvisitScheduledetails = createAsyncThunk(
    'visitschedulling/getscheduleitem',
    async (visitScheduleId) => {
        let result = await fetch(serverHost + "api/visitschedulling/getscheduleitem/" + visitScheduleId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getDraftSchedule = createAsyncThunk(
    "visitschedulling/getdraftschedule",
    async (visitScheduleId) => {
        let result = await fetch(serverHost + "api/visitschedulling/getdraftschedule/" + visitScheduleId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

export const getValidSchedule = createAsyncThunk(
    "visitschedulling/getvalidschedule",
    async (visitScheduleId) => {
        let result = await fetch(serverHost + "api/visitschedulling/getvalidschedule/" + visitScheduleId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const updateScheduleStatus = createAsyncThunk(
    "visitschedulling/updateschedulestatus",
    async (yourData) => {
        let visitScheduleId = yourData.visitScheduleId;
        let status = yourData.status;
        let result = await fetch(serverHost + "api/visitschedulling/updateschedulestatus/" + visitScheduleId + "/" + status, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getShedulebyVR = createAsyncThunk(
    "visitschedulling/getscheduleitem/visitrequest",
    async (visitScheduleId) => {
        let result = await fetch(serverHost + "api/visitschedulling/getscheduleitem/visitrequest/" + visitScheduleId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getvisitScheduleAsso = createAsyncThunk(
    'visitschedulling/getassoschedule',
    async (AssoMemberID) => {
        let result = await fetch(serverHost + "api/visitschedulling/getassoschedule/" + AssoMemberID, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

const visitScheduleSlice = createSlice({
    name: 'VisitSchedule',
    initialState: {
        visitSchLoading: false,
        visitSchedule: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSchedule.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(getAllSchedule.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(getAllSchedule.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addSchedule.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(addSchedule.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(addSchedule.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getvisitScheduledetails.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(getvisitScheduledetails.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(getvisitScheduledetails.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getValidSchedule.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(getValidSchedule.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(getValidSchedule.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getDraftSchedule.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(getDraftSchedule.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(getDraftSchedule.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getShedulebyVR.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(getShedulebyVR.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(getShedulebyVR.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateScheduleStatus.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(updateScheduleStatus.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(updateScheduleStatus.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getvisitScheduleAsso.pending, (state) => {
                state.visitSchLoading = true;
                state.visitSchedule = null;
                state.error = null;
            })
            .addCase(getvisitScheduleAsso.fulfilled, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = action.payload;
                state.error = null;
            })
            .addCase(getvisitScheduleAsso.rejected, (state, action) => {
                state.visitSchLoading = false;
                state.visitSchedule = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default visitScheduleSlice.reducer;