import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";
import { AES } from "crypto-js";

export const loginMember = createAsyncThunk(
    'employee/login',
    async (loginDatas) => {
        let result = await fetch(serverHost + "api/employee/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(loginDatas)
        })
        result = await result.json();  
        if (result.error === "") {
            const AccessToken = AES.encrypt(result.data.role, result.data.member_id);
            result.data.AccessToken=AccessToken.toString();
            localStorage.setItem('member-info', JSON.stringify(result.data));
        }
        return result;
    });

const memberSlice = createSlice({
    name: 'MemberLogin',
    initialState: {
        loading: false,
        member: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginMember.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(loginMember.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(loginMember.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default memberSlice.reducer;