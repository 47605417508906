import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getvisitScheduleAsso } from "../../redux/visitSchedule";
import { getAllReportByEmp } from "../../redux/visitReport";

function VisitReport() {

    const [visitSchedule, setVisitSchedule] = useState([]);
    const [visitreport, setVisitReport] = useState([]);
    const dispatch = useDispatch();
    const { visitSchLoading } = useSelector((state) => state.VisitSchedule);
    const { visitReportLoading } = useSelector((state) => state.VisitReport);
    const [mobNav, setMobNav] = useState(false);
    

    let AssoMemberID = JSON.parse(localStorage.getItem('member-info')).member_id;

    const refreshSheduleTable = useCallback(() => {
        dispatch(getvisitScheduleAsso(AssoMemberID)).then((result) => {
            if (result.payload.error === "") {
                setVisitSchedule(result.payload.data);
            }
        });
    }, [dispatch, AssoMemberID]);
    const refreshReportTable = useCallback(() => {
        setTimeout(() => {
            dispatch(getAllReportByEmp(AssoMemberID)).then((result) => {
                if (result.payload.error === "") {
                    setVisitReport(result.payload.data);
                }
            });
        }, 2000);
    }, [dispatch, AssoMemberID]);

    useEffect(() => {
        refreshSheduleTable();
        refreshReportTable();
    }, [refreshSheduleTable, refreshReportTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Visit Report | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Visit Report" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Visit", "Report"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to="/visit/upcoming" className="my-button bg-spblue"><i className="fas fa-file-download"></i> Upcoming Visits</Link>
                            <Link to="/visit/report/Draft" className="my-button bg-deeporange"><i className="fas fa-file-medical-alt"></i> Draft Reports</Link>
                            <Link to="/visit/report/All" className="my-button bg-green"><i className="far fa-id-card"></i> Reports</Link>
                        </div>
                        <div className="details-tabletitle">Upcoming Visits</div>
                        <div className="details-table two-table">
                            <table className="table_inner">
                                <thead className="my_head">
                                    <tr>
                                        <th className="normal_td table_blue">View</th>
                                        <td className="normal_td table_blue">Schedule ID</td>
                                        <td className="normal_td table_blue">Request ID</td>
                                        <td className="normal_td table_blue">Customer ID</td>
                                        <td className="normal_td table_blue">Customer Name</td>
                                        <td className="normal_td table_blue">Request For</td>
                                        <td className="normal_td table_blue">Status</td>
                                        <td className="normal_td table_blue">Report Date & Time</td>
                                        <td className="normal_td table_blue">Associates</td>
                                        <td className="normal_td table_blue">Coordinator</td>
                                    </tr>
                                </thead>
                                <tbody className="my_body">
                                    {
                                        (visitSchedule.length !== 0) ?
                                            visitSchedule.map((visit, index) =>
                                                <tr key={index}>
                                                    <td className="icon-tab"><Link to={"/visit/report/view/" + visit.vrid_i}><i className="far fa-eye"></i></Link></td>
                                                    <td>{visit.vsid}</td>
                                                    <td>{visit.vrid_i}</td>
                                                    <td>{visit.customerid}</td>
                                                    <td>{visit.customername}</td>
                                                    <td>{visit.requestfor}</td>
                                                    <td className="startwordcapital">{visit.status}</td>
                                                    <td>{visit.sh_date} at {visit.sh_time}</td>
                                                    <td>{visit.assoname}</td>
                                                    <td>{visit.visit_co}</td>
                                                </tr>
                                            ) :
                                            <tr className="no-contact-data">
                                                <td colSpan={10} className="errordata">No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="details-tabletitle">All Visit</div>
                        <div className="details-table two-table">
                           
                            <table className="table_inner">
                                <thead className="my_head">
                                    <tr>
                                        <td className="normal_td table_blue">View</td>
                                        <td className="normal_td table_blue">Report ID</td>
                                        <td className="normal_td table_blue">Request ID</td>
                                        <td className="normal_td table_blue">Schedule ID</td>
                                        <td className="normal_td table_blue">Performed By</td>
                                        <td className="normal_td table_blue">Start Date & Time</td>
                                        <td className="normal_td table_blue">End Date & Time</td>
                                        <td className="normal_td table_blue">Status</td>
                                    </tr>
                                </thead>
                                <tbody className="my_body">
                                    {
                                        (visitreport.length !== 0) ?
                                            visitreport.map((visit, index) =>
                                                <tr key={index}>
                                                    <td className="icon-tab"><Link to={"/visit/report/view/" + visit.vrid}><i className="far fa-eye"></i></Link></td>
                                                    <td>{visit.vreportid}</td>
                                                    <td>{visit.vrid}</td>
                                                    <td>{visit.vsid}</td>
                                                    <td>{visit.vperby}</td>
                                                    <td>{visit.vsdate}</td>
                                                    <td>{visit.vedate}</td>
                                                    <td className="startwordcapital">{visit.status}</td>
                                                </tr>
                                            ) :
                                            <tr className="no-contact-data">
                                                <td colSpan={8} className="errordata">No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            {
                (visitSchLoading || visitReportLoading) ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default VisitReport;