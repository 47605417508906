import dna from '../img/loading/dna_small.gif';
import fourdots from '../img/loading/four_dots_small.gif'

function Loading(props) {
    let type = props.Type;

    function getImg() {
        if (type === "dna")
            return <img src={dna} alt='loading.gif' className="loading-img loading-dna" />
        else if (type === "fourdots") {
            return <img src={fourdots} alt='loading.gif' className="loading-img loading-fourdot" />
        }
    }

    return (
        <>
            <div className="loading-background">
                <div className='loading-cont'>
                    {getImg()}
                </div>
            </div>
        </>
    );
}
export default Loading;