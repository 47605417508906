import { Helmet, HelmetProvider } from "react-helmet-async";
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import Loading from "../Loading";
import '../mainlayout.css';
import '../detailsfield.css';
import '../formfield.css';
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import men_member from '../../img/man-member.png';
import women_member from '../../img/women-member.png';
import { changeMemberPassword } from "../../redux/memberDetails";

function ChangePassword() {

    const dispatch = useDispatch();
    let memberid = JSON.parse(localStorage.getItem('member-info')).member_id;
    let memberDetails = JSON.parse(localStorage.getItem('member-info'));
    const { loading } = useSelector((state) => state.membersDetails);

    const [mobNav,setMobNav]=useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    let [disableButton, setdisableButton] = useState(true);
    let [disableField, setdisableField] = useState(true);
    let [errorMsg, setErrorMsg] = useState("");

    const [models, setModel] = useState(false);
    const [modelType, setModelType] = useState("");
    const [modelMsg, setModelMsg] = useState("");

    function checkPassword(data) {
        let errMsg = "";
        setNewPassword(data);
        if (data !== oldPassword) {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            setdisableField(true);
            if (!data.match(uppercaseRegExp))
                errMsg = "At least one Uppercase\n";
            if (!data.match(lowercaseRegExp))
                errMsg += "At least one Lowercase\n";
            if (!data.match(digitsRegExp))
                errMsg += "At least one digit\n";
            if (!data.match(specialCharRegExp))
                errMsg += "At least one Special Characters\n";
            if (!data.match(minLengthRegExp))
                errMsg += "At least minumum 8 characters\n";
            setErrorMsg(errMsg);
            if (errMsg === "") {
                setdisableField(false);
            }
        } else {
            setErrorMsg("New and Old Password is Same");
            setdisableField(true);
        }
    }
    function checkRePassword(data) {
        if (data === newPassword) {
            setdisableButton(false);
            setErrorMsg("");
        } else {
            setdisableButton(true);
            setErrorMsg("New Password Not Same");
        }
    }
    function changePassword(event) {
        event.preventDefault();
        let member_id = JSON.parse(localStorage.getItem('member-info')).member_id;;
        let yourData = { member_id, oldPassword, newPassword };
        dispatch(changeMemberPassword(yourData)).then((result) => {
            if (result.payload.error === "") {
                setModelMsg("Password Change Successfully");
                setModelType("sucess");
                setModel(true);
                document.getElementById("changePass").reset();
            } else {
                setModelMsg("Password Not Match");
                setModelType("error");
                setModel(true);
                document.getElementById("changePass").reset();
            }
        });
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Change Your Password | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Profile" valueMobNav={mobNav}/>
                <div className="right-total-body">
                    <HeaderNav navValue={["Profile", "Change Password"]} />
                    <div className="fulldetailsbody">

                        <div className="toolbar-container">
                            <Link to={"/profile"} className="my-button bg-yellow"><i className="fas fa-user-alt"></i> Back to Profile</Link>
                            <Link to={"/profile/edit/"} className="my-button bg-spblue"><i className="fas fa-pencil-alt"></i> Edit Profile</Link>
                        </div>
                        <div className="myRow">

                            <div className="details-formcontainer">
                                <div className="tab-details selected-tab">
                                    <div className="myRow">
                                        <div className="col-md-3">
                                            <div className="profile-img-cont">
                                                <img src={memberDetails.gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-deimg" />
                                            </div>
                                            <div className="text-cont">
                                                <div className="member-id-text">{memberid}</div>
                                                <div className="member-role-text">{memberDetails.role} <span className="member-status-text">({memberDetails.status})</span></div>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="title-label">Name</div>
                                                    <div className="details-text">{memberDetails.fname} {memberDetails.mname} {memberDetails.lname}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Gender</div>
                                                    <div className="details-text startwordcapital">{memberDetails.gender}</div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-12">
                                                    <div className="title-label">Email</div>
                                                    <div className="details-text">{memberDetails.email}</div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="title-label">Joining Date</div>
                                                    <div className="details-text">{memberDetails.science_date}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Aadhaar No.</div>
                                                    <div className="details-text">{memberDetails.aadhar}</div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="title-label">Last Updated Date</div>
                                                    <div className="details-text">{(memberDetails.updated_at !== undefined) ? memberDetails.updated_at.split("T")[0] : ""}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Created Date</div>
                                                    <div className="details-text">{(memberDetails.created_at !== undefined) ? memberDetails.created_at.split("T")[0] : ""}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="myRow">
                                        <form id="changePass" onSubmit={(event) => changePassword(event)}>
                                            <div className="input-formtitle">
                                                Chnage Your Password
                                            </div>
                                            <div className="myRow">
                                                <div className='col-md-4'>
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Old Password</label>
                                                        <input type="password" className="input-cell" placeholder="Enter Old Password" onChange={(e) => setOldPassword(e.target.value)} id="oldpassword" required />
                                                    </div>                                                    
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="input-formgroup">
                                                        <label className="input-label">New Password</label>
                                                        <input type="password" className="input-cell" placeholder="Enter New Password" onChange={(e) => checkPassword(e.target.value)} id="newpassword" required />
                                                    </div>
                                                    <div className='password_error'>
                                                        {errorMsg.split('\n').map((item, i) => (item !== "") ? <p key={i}>{item}</p> : "")}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    {(disableField) ?
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Rennter New Password</label>
                                                            <input type="password" className="input-cell" placeholder="Reenter New Password" onChange={(e) => checkRePassword(e.target.value)} id="renewpassword" required disabled />
                                                        </div> :
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Reenter New Password</label>
                                                            <input type="password" className="input-cell" placeholder="Reenter New Password" onChange={(e) => checkRePassword(e.target.value)} id="renewpassword" required />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className='offset-md-9 col-md-3 button-right'>
                                                    {(disableButton) ? <button type="submit" className="my-button bg-lightgreen" disabled>Change Password</button> : <button type="submit" className="my-button bg-lightgreen">Change Password</button>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className={(models === true) ? "mymodel modelfade modelshow" : "mymodel modelfade"}>
                        <div className={(modelType === "error") ? "mymodal-dialog mymodel-error" : "mymodal-dialog mymodel-sucess"}>
                            <div className="mymodal-content">
                                <div className="mymodal-header">
                                    Information
                                </div>

                                <div className="mymodal-body">
                                    {modelMsg}
                                </div>
                                <div className="mymodal-footer">
                                    <Link to={"/profile"} className="my-button bg-yellow"> Back to Profile </Link>
                                    {(modelType === "error") ? <div className="my-button bg-spblue" onClick={() => setModel(false)}> Retry </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                loading ? <Loading Type="dna" /> : ""
            }
        </>
    );
}
export default ChangePassword;