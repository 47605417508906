import { Link, useNavigate } from "react-router-dom";
import logo from '../../img/icon/logo-removebg.png';
import { AES, enc } from 'crypto-js';

function SideMenu(props) {

    let options = props.Options;

    let valueMobNav = props.valueMobNav;

    let AccessToken = JSON.parse(localStorage.getItem('member-info')).AccessToken;
    let member_id = JSON.parse(localStorage.getItem('member-info')).member_id;
    let role = "";

    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate("/login");
    }

    try {
        if (AccessToken !== "" || AccessToken !== null) {
            let bytes = AES.decrypt(AccessToken, member_id);
            const decrypted = bytes.toString(enc.Utf8);
            role = decrypted;
        } else {
            logout();
        }
    } catch (err) {
        logout();
    }

    function makeMainmenu(myOption, mlink, icon) {
        return options === myOption ? <Link to={mlink}><div className="menu-item selected">{icon} {myOption}</div></Link>
            : <Link to={mlink}><div className="menu-item">{icon} {myOption}</div></Link>
    }

    return (
        <>
            <div className={valueMobNav ? "left-total-body mob-nav-display" : "left-total-body"}>
                <div className="fullmenu">
                    <div className="sidemenu-itemlist">
                        {makeMainmenu("Dashboard", "/dashboard", < i className="fas fa-chart-pie"></i>)}
                        {makeMainmenu("Folders", "/admin/folders", <i className="fas fa-folder"></i>)}
                        {
                            (role === "") ? "" :
                                (role === "admin") ?
                                    <>
                                        {makeMainmenu("Anonymous", "/visit/anonymous", <i className="fas fa-user-secret"></i>)}
                                        {makeMainmenu("Visit Schedule", "/visit/schedule", <i className="far fa-calendar-plus"></i>)}
                                        {makeMainmenu("Visit Report", "/visit/report", <i className="fas fa-file-signature"></i>)}
                                        {makeMainmenu("Payments", "/visit/payment", <i className="fas fa-money-check-alt"></i>)}
                                        {makeMainmenu("Customers", "/customers", <i className="fas fa-house-user"></i>)}
                                        {makeMainmenu("Members", "/clients", <i className="fas fa-users"></i>)}
                                        {makeMainmenu("Employees", "/employees", <i className="fas fa-user-tie"></i>)}
                                        {makeMainmenu("Reference Data", "/admin/referencedata", <i className="fas fa-server"></i>)}
                                    </> :
                                    (role === "visit coordinator") ?
                                        <>
                                            {makeMainmenu("Visit Schedule", "/visit/schedule", <i className="far fa-calendar-plus"></i>)}
                                            {makeMainmenu("Clients", "/clients", <i className="fas fa-users"></i>)}
                                            {makeMainmenu("Customers", "/customers", <i className="fas fa-house-user"></i>)}
                                        </> :
                                        (role === "associate") ?
                                            <>
                                                {makeMainmenu("Visit Report", "/visit/report", <i className="fas fa-file-signature"></i>)}
                                                {makeMainmenu("Payments", "/visit/payment", <i className="fas fa-money-check-alt"></i>)}
                                            </>
                                            : ""

                        }
                        {makeMainmenu("Profile", "/profile", <i className="fas fa-user-alt"></i>)}
                        {/* {makeMainmenu("Chat", "/chat", <i className="far fa-comments"></i>)} */}
                        <div className="menu-item" onClick={logout}><i className="fas fa-sign-out-alt"></i> Logout</div>
                    </div>
                    <div className="logo-menu">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </div >
        </>
    );
}
export default SideMenu;