import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

function ProtectedMember(props) {
    let CMP = props.components;
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = useCallback(() => {
        const userToken = localStorage.getItem('member-info');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    },[navigate])

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn, checkUserToken]);
    return (
        <>
            {
                isLoggedIn ? <CMP /> : null
            }
        </>
    )
}
export default ProtectedMember;