import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getAllClients } from "../../redux/clientDetails";


function Clients() {

    const [clients, setClient] = useState([]);
    const dispatch = useDispatch();
    const { clientloading } = useSelector((state) => state.clientDetails);
    const [mobNav,setMobNav]=useState(false);



    const refreshTable = useCallback(() => {
        dispatch(getAllClients()).then((result) => {
            if (result.payload.error === "") {
                setClient(result.payload.data);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View All Members | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Members" valueMobNav={mobNav}/>
                <div className="right-total-body">
                    <HeaderNav navValue={["Members", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to="/clients/add" className="my-button bg-spblue"><i className="fas fa-user-plus"></i> Add Member</Link>                            
                        </div>
                        <div className="details-table">
                            <table className="table_inner"><thead className="my_head">
                                <tr>
                                    <td className="normal_td table_blue">View</td>
                                    <td className="normal_td table_blue">Member ID</td>
                                    <td className="normal_td table_blue">Name</td>
                                    <td className="normal_td table_blue">Customer Name</td>
                                    <td className="normal_td table_blue">Relationship</td>
                                    <td className="normal_td table_blue">Age</td>
                                    <td className="normal_td table_blue">Gender</td>
                                    <td className="normal_td table_blue">Status</td>
                                    <td className="normal_td table_blue cell-w768-nodisplay">City</td>
                                    <td className="normal_td table_blue">Zip Code</td>
                                </tr>
                            </thead>
                                <tbody className="my_body">
                                    {
                                        (clients.length !== 0) ?
                                            clients.map((client, index) =>
                                                <tr key={index}>
                                                    <td className="icon-tab"><Link to={"/clients/view/" + client.client_id}><i className="far fa-eye"></i></Link></td>
                                                    <td>{client.client_id}</td>
                                                    <td>{client.fname} {client.mname} {client.lname}</td>
                                                    <td>{client.customerfname} {client.customermname} {client.customerlname}</td>
                                                    <td>{client.relation}</td>
                                                    <td>{client.agerange}</td>
                                                    <td className="startwordcapital">{client.gender}</td>
                                                    <td className="startwordcapital">{client.status}</td>
                                                    <td className="cell-w768-nodisplay">{client.city}</td>
                                                    <td>{client.zipcode}</td>
                                                </tr>
                                            ) :
                                            <tr className="no-contact-data">
                                                <td colSpan={11} className="errordata">No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                clientloading ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default Clients;