import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import HeaderNav from "../layout/HeaderNav";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { addService, deleteService, getAllService, updateService } from "../../redux/adminFeature";
import SideMenu from "../layout/SideMenu";

function ServiceType() {

    const dispatch = useDispatch();

    const { adminloading } = useSelector((state) => state.AdminFeature);

    const [mobNav, setMobNav] = useState(false);

    const [serviceData, setServiceData] = useState([]);
    const [serviceid, setDatId] = useState("0");
    const [addEdit, setButt] = useState(true);
    const [servicetype, setServiceType] = useState("");


    const refreshTable = useCallback(() => {
        dispatch(getAllService()).then((result) => {
            if (result.payload.error === "") {
                setServiceData(result.payload.data);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);


    function updateServiceType(serviceid, index) {
        if (serviceid === serviceData[index].id) {
            setServiceType(serviceData[index].service);
            setDatId(serviceData[index].id);
            setButt(false);
        }
    }

    function deleteServiceType(serviceid) {
        dispatch(deleteService(serviceid)).then((result) => {
            if (result.payload.error === "") {
                refreshTable();
            }
        });
    }

    function addServiceData(event) {
        event.preventDefault();
        if (serviceid === "0") {
            let myData = {servicetype};
            dispatch(addService(myData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        } else {
            let myData = { serviceid, servicetype};
            dispatch(updateService(myData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        }
        document.getElementById("serviceData").reset();
        setServiceType("");
        setDatId("0");
        setButt(true);
    };

    function dataReset() {
        document.getElementById("serviceData").reset();
        setServiceType("");
        setDatId("0");
        setButt(true);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Service Type | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Reference Data" valueMobNav={mobNav} />
                <div className="right-total-body position-reative">
                    <HeaderNav navValue={["Reference Data", "Home & Hosplital", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <button type="button" className="my-button bg-yellow" onClick={() => dataReset()}><i className="fas fa-undo"></i> Reset Data</button>
                        </div>

                        <div className="myRow">
                            <div className="col-md-3 spc-mob-form">
                                <div className="details-title">Add Service Type</div>
                                <form id="serviceData" onSubmit={(event) => addServiceData(event)} className="myRow">
                                    <input type="hidden" defaultValue={serviceid} />
                                    <div className="input-formgroup">
                                        <label className="input-label">Service Type</label>
                                        <input className="input-cell" required="required" type="text" defaultValue={servicetype} placeholder="Enter Hospital or Home Name" onChange={(e) => setServiceType(e.target.value)} />
                                    </div>                                    
                                    <div className="text-cont">
                                        {
                                            (addEdit) ?
                                                <button type="submit" className="my-button bg-lightgreen"><i className="fas fa-plus"></i> Add Data</button>
                                                :
                                                <button type="submit" className="my-button bg-spblue"><i className="fas fa-pencil-alt"></i> Edit Data</button>
                                        }
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-9">
                                <div className="details-title">All Service Type</div>
                                <div className="details-table">
                                    <table className="table_inner"><thead className="my_head">
                                        <tr>
                                            <td className="normal_td table_blue mob-td-minwidth75">Action</td>
                                            <td className="normal_td table_blue">Service Type</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Created Date</td>
                                            <td className="normal_td table_blue cell-w768-nodisplay">Updated Date</td>
                                        </tr>
                                    </thead>
                                        <tbody className="my_body">
                                            {
                                                (serviceData.length !== 0) ?
                                                    serviceData.map((service, index) =>
                                                        <tr key={index}>
                                                            <td className="icon-tab">
                                                                <i className="fas fa-pencil-alt" onClick={() => updateServiceType(service.id, index)}></i>
                                                                <i className="far fa-trash-alt" onClick={() => deleteServiceType(service.id)}></i>
                                                            </td>
                                                            <td>{service.service}</td>
                                                            <td className="cell-w768-nodisplay">{service.created_at.split("T")[0]}</td>
                                                            <td className="cell-w768-nodisplay">{service.updated_at.split("T")[0]}</td>
                                                        </tr>
                                                    ) :
                                                    <tr className="no-contact-data">
                                                        <td colSpan={4} className="errordata">No Data Found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {
                adminloading ? <Loading Type="dna" /> : ""
            }
        </>
    )
}
export default ServiceType;
