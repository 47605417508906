import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../formfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import men_member from '../../img/client-men.png';
import women_member from '../../img/client-women.png';
import Loading from "../Loading";
import { editCustomer, getCustomerDetails } from "../../redux/customerDetails";

function EditCustomer() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { customerid } = useParams();
    const { loading } = useSelector((state) => state.customerDetails);
    const [tab, setTab] = useState(1);
    const [mobNav,setMobNav]=useState(false);

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [status, setStatus] = useState("");
    const [addressFL, setAddressFL] = useState("");
    const [addressSL, setAddressSL] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [locality, setLocality] = useState("");
    const [timezone, setTimeZone] = useState("");
    const [plantype, setPlanType] = useState("No Plan");
    const [visitsleft, setVisitsLeft] = useState("");
    const [sincedate, setSinceDate] = useState("");
    const [expirationdate, setExpirationDate] = useState("");
    const [cid, setcid] = useState("");

    function editCustomerForm(event) {
        event.preventDefault();
        let customerDate = { cid, status, gender, email, lastName, middleName, firstName, addressFL, addressSL, city, state, country, zipcode, locality, timezone, plantype, visitsleft, sincedate, expirationdate};
        dispatch(editCustomer(customerDate)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customers/view/" + customerid);
            }
        });
    }

    useEffect(() => {
        dispatch(getCustomerDetails(customerid)).then((result) => {
            if (result.payload.error === "") {
                let memberdata = result.payload.data;                
                setFirstName(memberdata.fname);
                setLastName(memberdata.lname);
                setMiddleName(memberdata.mname);
                setEmail(memberdata.email);
                setAddressFL(memberdata.fladdress);
                setAddressSL(memberdata.sladdress);
                setLocality(memberdata.locality);
                setCity(memberdata.city);
                setCountry(memberdata.country);
                setState(memberdata.state);
                setZipcode(memberdata.zipcode);
                setGender(memberdata.gender);
                setSinceDate(memberdata.sincedate);
                setExpirationDate(memberdata.expirationdate);
                setcid(memberdata.customer_id);
                setVisitsLeft(memberdata.visitsleft);
                setPlanType(memberdata.plantype);
                setTimeZone(memberdata.timezone);
                setStatus(memberdata.status);
            }
        });
    }, [dispatch,customerid]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Customer - {customerid} | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Customers" valueMobNav={mobNav}/>
                <div className="right-total-body">
                    <HeaderNav navValue={["Customers", "Edit", customerid]} />
                    <div className="fulldetailsbody">
                        <form onSubmit={(event) => editCustomerForm(event)}>
                            <div className="toolbar-container">
                                <Link to={"/customers/view/" + customerid} className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                                <button type="submit" className="my-button bg-spblue"><i className="far fa-edit"></i> Save</button>
                            </div>
                            <div className="myRow">
                            <div className="col-md-9">
                                    <div className="tab-container">
                                        <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Basic Details</div>
                                        <div className={(tab === 2) ? "tab selected" : "tab"} onClick={() => setTab(2)}>Residential Details</div>
                                        <div className={(tab === 3) ? "tab selected" : "tab"} onClick={() => setTab(3)}>Company Details</div>
                                    </div>
                                    <div className="details-formcontainer">
                                        <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">First Name</label>
                                                        <input className="input-cell" defaultValue={firstName} required="required" type="text" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Middle Name</label>
                                                        <input className="input-cell" defaultValue={middleName} type="text" placeholder="Enter Middle Name" onChange={(e) => setMiddleName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Last Name</label>
                                                        <input className="input-cell" defaultValue={lastName} required="required" type="text" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Email</label>
                                                        <input className="input-cell" defaultValue={email} required="required" type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="profile-pic-cont">
                                                        <img src={gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-upimg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Gender</label>
                                                                <select className="input-cell" onChange={(e) => setGender(e.target.value)} required value={gender}>
                                                                    <option value="" disabled="">Select any one</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                    <option value="others">Others</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-formgroup">
                                                                <label className="input-label">Status</label>
                                                                <select className="input-cell" onChange={(e) => setStatus(e.target.value)} required value={status}>
                                                                    <option value="" disabled="">Select any one</option>
                                                                    <option value="active">Active</option>
                                                                    <option value="not active">Not Active</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={(tab === 2) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Country</label>
                                                        <input className="input-cell" defaultValue={country} required="required" type="text" placeholder="Enter Country" onChange={(e) => setCountry(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">State</label>
                                                        <input className="input-cell" defaultValue={state} required="required" type="text" placeholder="Enter State" onChange={(e) => setState(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">City</label>
                                                        <input className="input-cell" defaultValue={city} required="required" type="text" placeholder="Enter City" onChange={(e) => setCity(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Address First Line</label>
                                                        <input className="input-cell" defaultValue={addressFL} required="required" type="text" placeholder="Enter Address First Line" onChange={(e) => setAddressFL(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Address Second Line</label>
                                                        <input className="input-cell" defaultValue={addressSL} type="text" placeholder="Enter Address Second Line" onChange={(e) => setAddressSL(e.target.value)} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Locality</label>
                                                        <input className="input-cell" defaultValue={locality} type="text" placeholder="Enter Locality" onChange={(e) => setLocality(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Zipcode</label>
                                                        <input className="input-cell" defaultValue={zipcode} required="required" type="text" placeholder="Enter Zipcode" onChange={(e) => setZipcode(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Time zone</label>
                                                        <input className="input-cell" defaultValue={timezone} type="text" placeholder="Enter Time zone" onChange={(e) => setTimeZone(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={(tab === 3) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Plan Type</label>
                                                        <select className="input-cell" onChange={(e) => setPlanType(e.target.value)} required value={status}>
                                                            <option value="No Plan">No Plan</option>                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Visit Left</label>
                                                        <input className="input-cell" defaultValue={visitsleft} type="number" placeholder="Enter Visit Left" onChange={(e) => setVisitsLeft(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Start Date</label>
                                                        <input className="input-cell" defaultValue={sincedate} type="date" placeholder="Enter Start Date" onChange={(e) => setSinceDate(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Expiration Date</label>
                                                        <input className="input-cell" defaultValue={expirationdate} type="date" placeholder="Enter Expiration Date" onChange={(e) => setExpirationDate(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 small-padding-r">
                                    <div className="mandetory-cont big-panel">
                                        <div className="title">Mandatory Information</div>
                                        {(firstName === "") ? <div className="alert alert-danger" role="alert">Need to fill First Name in Basic Details tab</div> : ""}
                                        {(lastName === "") ? <div className="alert alert-danger" role="alert">Need to fill Last Name in Basic Details tab</div> : ""}
                                        {(email === "") ? <div className="alert alert-danger" role="alert">Need to fill Email in Basic Details tab</div> : ""}
                                        {(gender === "") ? <div className="alert alert-danger" role="alert">Need to fill Gender in Basic Details tab</div> : ""}
                                        {(status === "") ? <div className="alert alert-danger" role="alert">Need to fill Status in Basic Details tab</div> : ""}
                                        {(country === "") ? <div className="alert alert-danger" role="alert">Need to fill Country in Residential Details tab</div> : ""}
                                        {(state === "") ? <div className="alert alert-danger" role="alert">Need to fill State in Residential Details tab</div> : ""}
                                        {(city === "") ? <div className="alert alert-danger" role="alert">Need to fill City in Residential Details tab</div> : ""}
                                        {(addressFL === "") ? <div className="alert alert-danger" role="alert">Need to fill Address First Line in Residential Details tab</div> : ""}
                                        {(zipcode === "") ? <div className="alert alert-danger" role="alert">Need to fill Zipcode in Residential Details tab</div> : ""}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            {
                loading ? <Loading Type="fourdots" /> : ""
            }
        </>
    )
}
export default EditCustomer;
