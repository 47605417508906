import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../formfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { uploadData } from "../../redux/adminFeature";

function FileUpload() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { clientid, cusid } = useParams();
    const { adminloading } = useSelector((state) => state.AdminFeature);
    const [tab, setTab] = useState(1);
    const [mobNav, setMobNav] = useState(false);

    const [type, setType] = useState("");

    const [files, setFile] = useState([]);


    function editClientForm(event) {
        event.preventDefault();
        const fileData = new FormData();
        fileData.append("customerid", cusid);
        fileData.append("clientid", clientid);
        fileData.append("Type", type);
        fileData.append('files', files);
        dispatch(uploadData(fileData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/clients/view/" + clientid);
            }
        });
    }

    function setFileData(data) {
        if (data !== undefined) {
            setFile(data);
        }
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Upload File for Member - {clientid} | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Members" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Members", "Upload", clientid]} />
                    <div className="fulldetailsbody">
                        <form onSubmit={(event) => editClientForm(event)}>
                            <div className="toolbar-container">
                                <Link to={"/clients/view/" + clientid} className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                                <button type="submit" className="my-button bg-spblue"><i className="far fa-edit"></i> Save</button>
                            </div>
                            <div className="myRow">
                                <div className="col-md-9">
                                    <div className="tab-container">
                                        <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Files Upload</div>
                                    </div>
                                    <div className="details-formcontainer">
                                        <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>
                                            <div className="myRow">
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Members ID</label>
                                                        <input className="input-cell" defaultValue={clientid} readOnly type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Customer ID</label>
                                                        <input className="input-cell" defaultValue={cusid} readOnly type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="myRow">
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">File Type</label>
                                                        <select className="input-cell" onChange={(e) => setType(e.target.value)} required value={type}>
                                                            <option value="" disabled="">Select any one</option>
                                                            <option value="Aadhar Card">Aadhar Card</option>
                                                            <option value="Medical Insurance Card">Medical Insurance Card</option>
                                                            <option value="Other Documents">Other Documents</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="file-upload-cont">
                                                        <input type="file" onChange={(e) => setFileData(e.target.files[0])} />
                                                        <div className="file-label">
                                                            <div><i className="fas fa-cloud-upload-alt"></i></div>
                                                            <div>{(files.length<1) ? "Click to Upload" : files.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 small-padding-r">

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            {
                adminloading ? <Loading Type="fourdots" /> : ""
            }
        </>
    )
}
export default FileUpload;
