import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllMember = createAsyncThunk(
    'employee/getAllMembers',
    async (data) => {
        let type = data.type;
        let memberuser_id = data.memberuser_id;
        let result = await fetch(serverHost + "api/employee/getmembers/" + type + "/" + memberuser_id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addMember = createAsyncThunk(
    'employee/addnewMember',
    async (memberData) => {
        let result = await fetch(serverHost + "api/employee/addnewmember", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(memberData)
        })
        result = await result.json();
        return result;
    });
export const getMemberDetails = createAsyncThunk(
    'employee/getMemberDetails',
    async (memberId) => {
        let result = await fetch(serverHost + "api/employee/getmemberdetails/" + memberId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addMemberContact = createAsyncThunk(
    'employee/addcontact',
    async (yourData) => {
        let memberid = yourData.mid;
        let contactDatas = yourData.contactData;
        let result = await fetch(serverHost + "api/employee/addcontact/" + memberid, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const getMemberContacts = createAsyncThunk(
    "employee/getcontacts",
    async (memberid) => {
        let result = await fetch(serverHost + "api/employee/getcontact/" + memberid, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const updateMemberContact = createAsyncThunk(
    'employee/updatecontact',
    async (contactData) => {
        let contactDatas = contactData;
        let result = await fetch(serverHost + "api/employee/updatecontact", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const deleteMemberContact = createAsyncThunk(
    'employee/contact/delete',
    async (contact_id) => {
        let contactid = contact_id;
        let result = await fetch(serverHost + "api/employee/deletecontact/" + contactid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });
export const resetMemberPassword = createAsyncThunk(
    'employee/resetpassword',
    async (memberid) => {
        let password = { "reset_password": "Password" };
        let result = await fetch(serverHost + "api/employee/resetpassword/" + memberid, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(password)
        })
        result = await result.json();
        return result;
    });
export const editMember = createAsyncThunk(
    'employee/editMember',
    async (memberData) => {
        let memberDatas = memberData;
        let result = await fetch(serverHost + "api/employee/updateprofile", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(memberDatas)
        })
        result = await result.json();
        return result;
    });
export const changeMemberPassword = createAsyncThunk(
    'employee/changePassword',
    async (memberData) => {
        let memberDatas = memberData;
        let result = await fetch(serverHost + "api/employee/updatepassword", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(memberDatas)
        })
        result = await result.json();
        return result;
    });
export const editProfile = createAsyncThunk(
    'employee/editProfile',
    async (memberData) => {
        let memberDatas = memberData;
        let result = await fetch(serverHost + "api/employee/updateprofile", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(memberDatas)
        })
        result = await result.json();
        if (result.error === "") {
            localStorage.setItem('member-info', JSON.stringify(result.data));
        }
        return result;
    });
const memberDetailsSlice = createSlice({
    name: 'MemberDetails',
    initialState: {
        loading: false,
        member: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllMember.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(getAllMember.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(getAllMember.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addMember.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(addMember.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(addMember.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getMemberDetails.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(getMemberDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(getMemberDetails.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deleteMemberContact.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(deleteMemberContact.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(deleteMemberContact.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateMemberContact.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(updateMemberContact.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(updateMemberContact.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getMemberContacts.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(getMemberContacts.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(getMemberContacts.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addMemberContact.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(addMemberContact.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(addMemberContact.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(editMember.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(editMember.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(editMember.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(resetMemberPassword.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(resetMemberPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(resetMemberPassword.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(editProfile.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(editProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(editProfile.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(changeMemberPassword.pending, (state) => {
                state.loading = true;
                state.member = null;
                state.error = null;
            })
            .addCase(changeMemberPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
                state.error = null;
            })
            .addCase(changeMemberPassword.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default memberDetailsSlice.reducer;