import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getAllPayment } from "../../redux/visitPayment";

function Payments() {

    const [payments, setPayments] = useState([]);
    const dispatch = useDispatch();
    const { visitPaymentLoading } = useSelector((state) => state.VisitPayment);

    const [mobNav, setMobNav] = useState(false);

    const member_id = JSON.parse(localStorage.getItem('member-info')).member_id;

    const refreshTable = useCallback(() => {
        dispatch(getAllPayment(member_id)).then((result) => {
            if (result.payload.error === "") {
                setPayments(result.payload.data);
            }
        });
    }, [dispatch, member_id]);

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View All Payments | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Payments" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Payments", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to="/visit/payment/new" className="my-button bg-spblue"><i className="fas fa-user-plus"></i> Add Payment</Link>
                        </div>
                        <div className="details-table">
                            <table className="table_inner">
                                <thead className="my_head">
                                    <tr>
                                        <td className="normal_td table_blue">Request ID</td>
                                        <td className="normal_td table_blue">Invoice ID</td>
                                        <td className="normal_td table_blue">Invoice Amount</td>
                                        <td className="normal_td table_blue">Invoice Date</td>
                                        <td className="normal_td table_blue">Payment Status</td>
                                        <td className="normal_td table_blue">Payment Date</td>
                                        <td className="normal_td table_blue">Created By</td>
                                    </tr>
                                </thead>
                                <tbody className="my_body">
                                    {
                                        (payments.length !== 0) ?
                                            payments.map((payment, index) =>
                                                <tr key={payment.id}>
                                                    <td>{payment.vrid}</td>
                                                    <td>{payment.invid}</td>
                                                    <td>{payment.invamount} </td>
                                                    <td>{payment.invdate.replace("T"," ")}</td>
                                                    <td className="startwordcapital">{payment.paysta}</td>
                                                    <td>{payment.paydate.replace("T"," ")}</td>
                                                    <td>{payment.memberfname} {payment.membermname} {payment.memberlname}</td>
                                                </tr>
                                            ) :
                                            <tr className="no-contact-data">
                                                <td colSpan={6} className="errordata">No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                visitPaymentLoading ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default Payments;