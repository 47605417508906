import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../../css/Client.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import men_member from '../../img/customer-men.png';
import women_member from '../../img/customer-women.png';
import men_client from '../../img/client-men.png';
import women_client from '../../img/client-women.png';
import { getFullVisitDetails, notifyMessage } from "../../redux/visitRequest";
import { serverHost } from "../../Store/config";

function ViewVisitRequest() {

    const [visitRequestDetails, setVisitRequest] = useState([]);
    const [visitScheduleDetails, setVisitSchedule] = useState([]);
    const [visitReportDetails, setVisitReport] = useState([]);
    const [customeruser, setCustomerUser] = useState();
    const [clientuser, setClientUser] = useState();
    const [visitTask, setVisitTask] = useState([]);
    const [notifyMessages, setNotifyMessage] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState("No Data Found");

    const [assoMsg, setAssomsg] = useState("");

    const handleDownload = async (filename) => {
        try {
            const response = await fetch(serverHost + "api/admin/downloadfile/reportattachment/" + filename);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    const dispatch = useDispatch();

    const { visitRequestLoading } = useSelector((state) => state.VisitRequest);
    const [tab, setTab] = useState(1);
    const [mobNav, setMobNav] = useState(false);
    const [models, setModel] = useState(false);

    let { requestId } = useParams();

    let member_id = JSON.parse(localStorage.getItem('member-info')).member_id;

    const refreshSheduleTable = useCallback(() => {
        dispatch(getFullVisitDetails(requestId)).then((result) => {
            if (result.payload.error === "") {
                let data = result.payload;
                setVisitRequest(data.VisitRequest);
                setVisitSchedule(data.VisitSchedule);
                setVisitReport(data.VisitReport);
                setCustomerUser(data.UserCustomer);
                setClientUser(data.UserClient);
                setVisitTask(data.VisitTasks);
                setNotifyMessage(data.NotifyMessage);
                setPaymentDetails(data.Payment);
            }
        });
    }, [dispatch, requestId]);

    function notifyAsso(event) {
        event.preventDefault();
        let recivername = visitScheduleDetails.assoname;
        let reciverid = visitScheduleDetails.assoid;
        let sendername = visitScheduleDetails.visit_co;
        let senderid = visitScheduleDetails.visit_coid;
        let vrid = visitRequestDetails.vrid;
        let msg = assoMsg;
        let notifyData = { vrid, senderid, sendername, reciverid, recivername, msg };
        dispatch(notifyMessage(notifyData)).then((result) => {
            if (result.payload.error === "") {
                refreshSheduleTable();
                setModel(false);
            }
        });
    }

    useEffect(() => {
        refreshSheduleTable();
    }, [refreshSheduleTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View Visit Request - {requestId} | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Visit Schedule" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Visit", "Request"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to={"/visit/schedule"} className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                            {(visitScheduleDetails === "No Data Found") ?
                                <Link to={"/visit/schedule/new/" + requestId} className="my-button bg-spblue"><i className="far fa-calendar-check"></i> Schedule a Visit</Link>
                                :
                                (visitScheduleDetails.visit_coid !== member_id) ?
                                    ""
                                    : (visitRequestDetails.vsid === null) ?
                                        <Link to={"/visit/schedule/edit/" + visitScheduleDetails.vsid} className="my-button bg-deeporange"><i className="far fa-calendar-alt"></i> Edit Draft Schedule</Link>
                                        :
                                        (visitRequestDetails.vreid === null) ?
                                            <>
                                                <Link to={"/visit/schedule/edit/" + visitScheduleDetails.vsid} className="my-button bg-lightgreen"><i className="far fa-calendar-alt"></i> Edit Schedule</Link>
                                                <div className="my-button bg-lightblue" onClick={() => setModel(true)}><i className="fas fa-sms"></i> Notify Associate</div>
                                            </>
                                            : ""
                            }
                        </div>
                        <div className="myRow">
                            <div className="full-mod-tab">
                                <div className="tab-container">
                                    <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Request Details</div>
                                    <div className={(tab === 2) ? "tab selected" : "tab"} onClick={() => setTab(2)}>Schedule Details</div>
                                    <div className={(tab === 3) ? "tab selected" : "tab"} onClick={() => setTab(3)}>Report Details</div>
                                    <div className={(tab === 4) ? "tab selected" : "tab"} onClick={() => setTab(4)}>Customer Details</div>
                                    <div className={(tab === 5) ? "tab selected" : "tab"} onClick={() => setTab(5)}>Member Details</div>
                                    <div className={(tab === 6) ? "tab selected" : "tab"} onClick={() => setTab(6)}>Payment Details</div>
                                </div>
                            </div>
                            <div className="details-formcontainer big-panel">
                                <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>
                                    <div className="myRow">
                                        <div className="col-md-9">
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="title-label">Visit ID</div>
                                                    <div className="details-text">{visitRequestDetails.vrid}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Customer Name</div>
                                                    <div className="details-text">{visitRequestDetails.customerfname} {visitRequestDetails.customermname} {visitRequestDetails.customerlname}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Customer ID</div>
                                                    <div className="details-text">{visitRequestDetails.customer_cid}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Request For</div>
                                                    <div className="details-text">{visitRequestDetails.request_for}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Request Status</div>
                                                    <div className="details-text startwordcapital">{visitRequestDetails.status}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Requested Date & Time</div>
                                                    <div className="details-text">{visitRequestDetails.visit_date} {visitRequestDetails.visit_time}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Is this an Emergency?</div>
                                                    <div className="details-text startwordcapital">{visitRequestDetails.emergency}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Service Types</div>
                                                    <div className="details-text">{visitRequestDetails.servicetype}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Preferred Associate</div>
                                                    <div className="details-text">{visitRequestDetails.prefered_asso}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Request Details</div>
                                                    <div className="details-text">{visitRequestDetails.task_details}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Additional Notes</div>
                                                    <div className="details-text">{visitRequestDetails.aditianalnote}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Last Updated Date</div>
                                                    <div className="details-text">{(visitRequestDetails.updated_at !== undefined) ? visitRequestDetails.updated_at.split("T")[0] : ""}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Created Date</div>
                                                    <div className="details-text">{(visitRequestDetails.created_at !== undefined) ? visitRequestDetails.created_at.split("T")[0] : ""}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(tab === 2) ? "tab-details selected-tab" : "tab-details"}>
                                    {(visitScheduleDetails === "No Data Found") ?
                                        <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="no-data">
                                                    Not Schedule Yet<br />
                                                    <Link to={"/visit/schedule/new/" + requestId} className="my-button bg-spblue spc-button"><i className="far fa-calendar-check"></i> Schedule a Visit</Link>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        (visitScheduleDetails.visit_coid !== member_id) ?
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="no-data">
                                                        {visitScheduleDetails.visit_co} Already Created a Draft Schedule<br />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="myRow padding-l-med">
                                                    <div className="col-md-8">
                                                        <div className="title-label">Visit Schedule ID</div>
                                                        <div className="details-text">{visitScheduleDetails.vsid}</div>
                                                    </div>
                                                </div>
                                                <div className="myRow padding-l-med">
                                                    <div className="col-md-4">
                                                        <div className="title-label">Schedule Date</div>
                                                        <div className="details-text">{visitScheduleDetails.sh_date}</div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="title-label">Schedule Time</div>
                                                        <div className="details-text">{visitScheduleDetails.sh_time}</div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="title-label">Schedule Status</div>
                                                        <div className="details-text">{visitScheduleDetails.status}</div>
                                                    </div>
                                                </div>
                                                <div className="myRow padding-l-med">
                                                    <div className="col-md-4">
                                                        <div className="title-label">Assign Associate</div>
                                                        <div className="details-text">{visitScheduleDetails.assoname}</div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="title-label">Visit Coordinator</div>
                                                        <div className="details-text">{visitScheduleDetails.visit_co}</div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="title-label">Note</div>
                                                        <div className="details-text">{visitScheduleDetails.note}</div>
                                                    </div>
                                                </div>
                                                <div className="myRow padding-l-med">
                                                    <div className="col-md-4">
                                                        <div className="title-label">Last Updated Date</div>
                                                        <div className="details-text">{(visitScheduleDetails.updated_at !== undefined) ? visitScheduleDetails.updated_at.split("T")[0] : ""}</div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="title-label">Created Date</div>
                                                        <div className="details-text">{(visitScheduleDetails.created_at !== undefined) ? visitScheduleDetails.created_at.split("T")[0] : ""}</div>
                                                    </div>
                                                </div>

                                                {
                                                    (notifyMessages.length !== 0) ?
                                                        <div className="myRow padding-l-med">
                                                            <div className="col-md-12">
                                                                <div className="details-table">
                                                                    <table className="table_inner">
                                                                        <thead className="my_head">
                                                                            <tr>
                                                                                <td className="normal_td table_blue">Sender Name</td>
                                                                                <td className="normal_td table_blue">Message</td>
                                                                                <td className="normal_td table_blue">Message Date</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="my_body">
                                                                            {
                                                                                notifyMessages.map((message, index) =>
                                                                                    <tr key={index}>
                                                                                        <td>{message.sendername}</td>
                                                                                        <td >{message.msg}</td>
                                                                                        <td>{(message.created_at !== undefined) ? message.created_at.split("T")[0] + " at " + message.created_at.split("T")[1].split(".")[0] : ""}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                            </>
                                    }

                                </div>
                                <div className={(tab === 3) ? "tab-details selected-tab" : "tab-details"}>
                                    {(visitReportDetails === "No Data Found") ?
                                        <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="no-data">
                                                    No Report Found<br />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-8">
                                                    <div className="title-label">Visit Report ID</div>
                                                    <div className="details-text">{visitReportDetails.vreportid}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Vist Start Date & Time</div>
                                                    <div className="details-text">{visitReportDetails.vsdate}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Vist End Date & Time</div>
                                                    <div className="details-text">{visitReportDetails.vedate}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Visit Performed</div>
                                                    <div className="details-text">{visitReportDetails.vperby}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="title-label">Visit Report Status</div>
                                                    <div className="details-text">{visitReportDetails.status}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-6">
                                                    <div className="title-label">Visit Report Details</div>
                                                    <div className="details-text">{visitReportDetails.reportdetails}</div>
                                                </div>
                                                {
                                                    (visitRequestDetails.comments !== null) ?
                                                        <div className="col-md-6">
                                                            <div className="title-label">Customer Comment/Suggestions</div>
                                                            <div className="details-text">{visitRequestDetails.comments}</div>
                                                        </div>
                                                        : ""
                                                }
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="title-label">Report Attachment</div>
                                                    <div className="file-list list-view">
                                                        {
                                                            (visitReportDetails.attachment !== undefined && visitReportDetails.attachment !== "") ?
                                                                visitReportDetails.attachment.split("$;").map((file, index) =>
                                                                    <div onClick={()=>handleDownload(file)} className="file-item" key={index}>
                                                                        <div className="file-type">
                                                                            {file.split(".")[1]}
                                                                        </div>
                                                                        <div className="file-name">
                                                                            {file.split(".")[0].split("_")[0]}
                                                                        </div>
                                                                        <div className="file-upload">
                                                                            <i className="fas fa-download"></i>
                                                                        </div>
                                                                    </div>
                                                                ) : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="details-table">
                                                        <table className="table_inner">
                                                            <thead className="my_head">
                                                                <tr>
                                                                    <td className="normal_td table_blue">Requestetd Task</td>
                                                                    <td className="normal_td table_blue">Task Status</td>
                                                                    <td className="normal_td table_blue">Task Percentage</td>
                                                                    <td className="normal_td table_blue">Task Note</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="my_body">
                                                                {
                                                                    (visitTask !== "No Data Found") ?
                                                                        visitTask.map((task, index) =>
                                                                            <tr key={index}>
                                                                                <td>{task.task}</td>
                                                                                <td className="startwordcapital">{task.taskstatus}</td>
                                                                                <td>{task.persentage} %</td>
                                                                                <td>{task.tasknote}</td>
                                                                            </tr>
                                                                        ) :
                                                                        <tr className="no-contact-data">
                                                                            <td colSpan={10} className="errordata">No Data Found</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-4">
                                                    <div className="title-label">Last Updated Date</div>
                                                    <div className="details-text">{(visitReportDetails.updated_at !== undefined) ? visitReportDetails.updated_at.split("T")[0] : ""}</div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="title-label">Created Date</div>
                                                    <div className="details-text">{(visitReportDetails.created_at !== undefined) ? visitReportDetails.created_at.split("T")[0] : ""}</div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={(tab === 4) ? "tab-details selected-tab" : "tab-details"}>
                                    {(customeruser !== undefined && customeruser !== null) ?
                                        <>
                                            <div className="myRow profile-backgound">
                                                <div className="fullpofile-image">
                                                    <div className="profile-pic-cont">
                                                        <img src={customeruser.gender === "female" ? women_client : men_client} alt="Profile Pic" className="profile-upimg" />
                                                    </div>
                                                    <div className="member-id-text">{(customeruser.customer_cid !== undefined) ? customeruser.customer_cid : "-"}</div>
                                                    <Link to={"/customers/view/" + customeruser.customer_cid} className="my-button bg-lightblue spc-button"> <i className="fas fa-house-user"></i> View Customer Profile </Link>
                                                </div>
                                                <div className="profile-details">
                                                    <div className="profile-item">
                                                        <label>Name</label>
                                                        {(customeruser.fname !== undefined) ? customeruser.fname : "-"} {(customeruser.mname !== undefined) ? customeruser.mname : "-"}  {(customeruser.lname !== undefined) ? customeruser.lname : "-"}
                                                    </div>
                                                    <div className="profile-item">
                                                        <label>Email</label>
                                                        {(customeruser.email !== undefined) ? customeruser.email : "-"}
                                                    </div>
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Address</label>
                                                                {(customeruser.fladdress !== undefined) ? customeruser.fladdress : "-"}<br />
                                                                {(customeruser.sladdress !== undefined) ? customeruser.sladdress : "-"}<br />
                                                                {(customeruser.state !== undefined) ? customeruser.state : "-"}, {(customeruser.city !== undefined) ? customeruser.city : "-"}<br />
                                                                Zip Code - {(customeruser.zipcode !== undefined) ? customeruser.zipcode : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Country</label>
                                                                {(customeruser.country !== undefined) ? customeruser.country : "-"}
                                                            </div>
                                                            <div className="profile-item">
                                                                <label>Locality  & Time Zone</label>
                                                                {(customeruser.locality !== undefined) ? customeruser.locality : "-"} ( {(customeruser.timezone !== undefined) ? customeruser.timezone : "-"} )
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="details-table">
                                                        <table className="table_inner">
                                                            <thead className="my_head">
                                                                <tr>
                                                                    <td className="normal_td table_blue">Type</td>
                                                                    <td className="normal_td table_blue">Phone</td>
                                                                    <td className="normal_td table_blue">WhatsApp</td>
                                                                    <td className="normal_td table_blue cell-w768-nodisplay">Email</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="my_body">
                                                                {

                                                                    (customeruser.contact.length > 0) ?
                                                                        customeruser.contact.map((contact, index) =>
                                                                            <tr key={index}>
                                                                                <td>{contact.contact_type}</td>
                                                                                <td>{contact.phone}</td>
                                                                                <td>{contact.whatsapp}</td>
                                                                                <td className="cell-w768-nodisplay">{contact.email}</td>
                                                                            </tr>
                                                                        ) :
                                                                        <tr className="no-contact-data">
                                                                            <td colSpan={4} className="errordata">No Contact Added</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="no-data">
                                                    Customer Data Not Found
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <div className={(tab === 5) ? "tab-details selected-tab" : "tab-details"}>
                                    {(clientuser !== undefined && clientuser !== null) ?
                                        <>
                                            <div className="myRow profile-backgound">
                                                <div className="fullpofile-image">
                                                    <div className="profile-pic-cont">
                                                        <img src={clientuser.gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-upimg" />
                                                    </div>
                                                    <div className="member-id-text">{(clientuser.client_id !== undefined) ? clientuser.client_id : "-"}</div>
                                                    <Link to={"/clients/view/" + clientuser.client_id} className="my-button bg-lightgreen spc-button"> <i className="fas fa-users"></i> View Member Profile </Link>
                                                </div>
                                                <div className="profile-details">
                                                    <div className="profile-item">
                                                        <label>Name</label>
                                                        {(clientuser.fname !== undefined) ? clientuser.fname : "-"} {(clientuser.mname !== undefined) ? clientuser.mname : "-"}  {(clientuser.lname !== undefined) ? clientuser.lname : "-"}
                                                    </div>
                                                    <div className="profile-item">
                                                        <label>Email</label>
                                                        {(clientuser.email !== undefined) ? clientuser.email : "-"}
                                                    </div>
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Address</label>
                                                                {(clientuser.fladdress !== undefined) ? clientuser.fladdress : "-"}<br />
                                                                {(clientuser.sladdress !== undefined) ? clientuser.sladdress : "-"}<br />
                                                                {(clientuser.state !== undefined) ? clientuser.state : "-"}, {(clientuser.city !== undefined) ? clientuser.city : "-"}<br />
                                                                Zip Code - {(clientuser.zipcode !== undefined) ? clientuser.zipcode : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Country</label>
                                                                {(clientuser.country !== undefined) ? clientuser.country : "-"}
                                                            </div>
                                                            <div className="profile-item">
                                                                <label>Locality  & Time Zone</label>
                                                                {(clientuser.locality !== undefined) ? clientuser.locality : "-"} ( {(clientuser.timezone !== undefined) ? clientuser.timezone : "-"} )
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="details-table">
                                                        <table className="table_inner">
                                                            <thead className="my_head">
                                                                <tr>
                                                                    <td className="normal_td table_blue">Type</td>
                                                                    <td className="normal_td table_blue">Phone</td>
                                                                    <td className="normal_td table_blue">WhatsApp</td>
                                                                    <td className="normal_td table_blue cell-w768-nodisplay">Email</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="my_body">
                                                                {
                                                                    (clientuser.contact.length > 0) ?
                                                                        clientuser.contact.map((contact, index) =>
                                                                            <tr key={index}>
                                                                                <td>{contact.contact_type}</td>
                                                                                <td>{contact.phone}</td>
                                                                                <td>{contact.whatsapp}</td>
                                                                                <td className="cell-w768-nodisplay">{contact.email}</td>
                                                                            </tr>
                                                                        ) :
                                                                        <tr className="no-contact-data">
                                                                            <td colSpan={4} className="errordata">No Contact Added</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="no-data">
                                                    Member Not Linked<br />
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <div className={(tab === 6) ? "tab-details selected-tab" : "tab-details"}>
                                    {(paymentDetails === "No Data Found") ?
                                        <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="no-data">
                                                    No Payment Found<br />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-4">
                                                    <div className="title-label">Invoice ID</div>
                                                    <div className="details-text">{paymentDetails.invid}</div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="title-label">Created By</div>
                                                    <div className="details-text">{paymentDetails.memberfname} {paymentDetails.membermname} {paymentDetails.memberlname}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-4">
                                                    <div className="title-label">Invoice Amount</div>
                                                    <div className="details-text">{paymentDetails.invamount}</div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="title-label">Invoice Date</div>
                                                    <div className="details-text">{paymentDetails.invdate.replace("T", " ")}</div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-4">
                                                    <div className="title-label">Payment Status</div>
                                                    <div className="details-text">{paymentDetails.paysta}</div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="title-label">Payment Date</div>
                                                    <div className="details-text">{paymentDetails.paydate.replace("T", " ")}</div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <div className={(models === true) ? "mymodel modelfade modelshow" : "mymodel modelfade"}>
                    <div className="mymodal-dialog">
                        <div className="mymodal-content">
                            <form onSubmit={(event) => notifyAsso(event)}>
                                <div className="mymodal-header">
                                    Notify Associate
                                </div>
                                <div className="mymodal-body input-model">
                                    <div className="myRow">
                                        <div className="col-md-12">
                                            <div className="input-formgroup">
                                                <label className="input-label">Message for Associate</label>
                                                <textarea className="input-cell resize-none" placeholder="Enter Message" onChange={(e) => setAssomsg(e.target.value)} required>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mymodal-footer">
                                    <button type="submit" className="my-button bg-lightgreen"> Notify </button>
                                    <div className="my-button bg-lightred" onClick={() => setModel(false)}> Close </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                visitRequestLoading ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default ViewVisitRequest;