import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getAllCustomers } from "../../redux/customerDetails";

function Customers() {

    const [customers, setCustomers] = useState([]);
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.customerDetails);
    const [mobNav,setMobNav]=useState(false);


    const refreshTable = useCallback(() => {
        dispatch(getAllCustomers()).then((result) => {
            if (result.payload.error === "") {
                setCustomers(result.payload.data);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View All Customers | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Customers" valueMobNav={mobNav}/>
                <div className="right-total-body">
                    <HeaderNav navValue={["Customers", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to="/customers/add" className="my-button bg-spblue"><i className="fas fa-user-plus"></i> Add Customer</Link>
                        </div>
                        <div className="details-table">
                            <table className="table_inner"><thead className="my_head">
                                <tr>
                                    <td className="normal_td table_blue">View</td>
                                    <td className="normal_td table_blue">Customer ID</td>
                                    <td className="normal_td table_blue">Name</td>
                                    <td className="normal_td table_blue">Email</td>
                                    <td className="normal_td table_blue">Gender</td>
                                    <td className="normal_td table_blue">Status</td>
                                    <td className="normal_td table_blue">City</td>
                                    <td className="normal_td table_blue">Zip Code</td>
                                    <td className="normal_td table_blue">Plantype</td>
                                    <td className="normal_td table_blue cell-w768-nodisplay">Visit Left</td>                                    
                                </tr>
                            </thead>
                                <tbody className="my_body">
                                    {
                                        (customers.length !== 0) ?
                                            customers.map((customer, index) =>
                                                <tr key={index}>
                                                    <td className="icon-tab"><Link to={"/customers/view/" + customer.customer_cid}><i className="far fa-eye"></i></Link></td>
                                                    <td>{customer.customer_cid}</td>
                                                    <td>{customer.fname} {customer.mname} {customer.lname}</td>
                                                    <td>{customer.email}</td>
                                                    <td className="startwordcapital">{customer.gender}</td>
                                                    <td className="startwordcapital">{customer.status}</td>
                                                    <td>{customer.city}</td>
                                                    <td>{customer.zipcode}</td>
                                                    <td>{customer.plantype}</td>
                                                    <td className="cell-w768-nodisplay">{customer.visitsleft}</td>                                                    
                                                </tr>
                                            ) :
                                            <tr className="no-contact-data">
                                                <td colSpan={10} className="errordata">No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default Customers;