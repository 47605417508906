import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../../css/Client.css';
import '../detailsfield.css';
import '../formfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import men_member from '../../img/customer-men.png';
import women_member from '../../img/customer-women.png';
import men_client from '../../img/client-men.png';
import women_client from '../../img/client-women.png';
import { getFullVisitDetails } from "../../redux/visitRequest";
import { getvisitScheduledetails, updateSchedule } from "../../redux/visitSchedule";
import { getAllMember } from "../../redux/memberDetails";

function UpdateVisitSchedule() {

    const [visitRequest, setVisitRequest] = useState([]);
    const [customeruser, setCustomerUser] = useState();
    const [clientuser, setClientUser] = useState();

    const [visischedule_id, seVsid] = useState("");
    const [vrid_i, setVrid_i] = useState("");
    const [customerid, setCustomerid] = useState("");
    const [customername, setCustomername] = useState("");
    const [requestfor, setRequestfor] = useState("");
    const [requestforid, setRequestforid] = useState("");
    const [assoid, setAssoid] = useState("");
    const [assoname, setAssoname] = useState("");
    const [sh_date, setSh_date] = useState("");
    const [sh_time, setSh_time] = useState("");
    const [note, setNote] = useState("");
    const [visit_co, setVisit_co] = useState("");
    const [intsta, setintSta] = useState("");

    let status;

    const [visit_coid, setVisit_coid] = useState("");

    let [assouser, setAssoUser] = useState([]);

    let member_id = JSON.parse(localStorage.getItem('member-info')).member_id;
    let memberuser_id = JSON.parse(localStorage.getItem('member-info')).member_id;
    let member = JSON.parse(localStorage.getItem('member-info'));

    const type = "associate";

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { visitRequestLoading } = useSelector((state) => state.VisitRequest);
    const { loading } = useSelector((state) => state.membersDetails);

    const [tab, setTab] = useState(1);
    const [mobNav, setMobNav] = useState(false);

    let { visitScheduleId } = useParams();

    function blankRequest(event) {
        event.preventDefault();
    };
    function newSchedule() {
        status = "assigned";
        addVisitScheduleForm();
    };
    function draftSchedule() {
        status = "draft";
        addVisitScheduleForm();
    };

    function addVisitScheduleForm() {
        let scheduleData = { visischedule_id, vrid_i, customerid, customername, requestfor, requestforid, assoid, assoname, sh_date, sh_time, note, visit_co, status, visit_coid };
        dispatch(updateSchedule(scheduleData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/visit/request/view/" + vrid_i);
            }
        });
    }

    const getAssosData = useCallback(() => {
        setTimeout(() => {
            dispatch(getAllMember({ type, memberuser_id })).then((result) => {
                if (result.payload.error === "") {
                    setAssoUser(result.payload.data);
                }
            });
        }, 2000);
    }, [dispatch, type, memberuser_id]);

    const getAsso = useCallback((event) => {
        let custo_id = event.target.value;
        setAssoid(custo_id);
        setAssoname(event.target.options[event.target.selectedIndex].text);
    }, [setAssoid, setAssoname])

    const refreshOnlySchedule = useCallback(() => {

        dispatch(getvisitScheduledetails(visitScheduleId)).then((result) => {
            if (result.payload.error === "") {
                let data = result.payload.data;
                setVrid_i(data.vrid_i);
                setCustomerid(data.customerid);
                setCustomername(data.customername);
                setRequestfor(data.requestfor);
                setRequestforid(data.requestforid);
                let coname = member.fname + " ";
                coname += (member.mname != null) ? member.mname + " " : "";
                coname += member.lname;
                setVisit_co(coname);
                setVisit_coid(member_id);
                setAssoid(data.assoid);
                setAssoname(data.assoname);
                setSh_date(data.sh_date);
                setSh_time(data.sh_time);
                status = data.status;
                setintSta(data.status);
                setNote(data.note);
                seVsid(data.vsid);
            }
        });


    }, [dispatch, visitScheduleId]);

    const refreshSheduleTable = useCallback(() => {
        if (vrid_i !== "") {
            dispatch(getFullVisitDetails(vrid_i)).then((result) => {
                if (result.payload.error === "") {
                    let data = result.payload;
                    setVisitRequest(data.VisitRequest);
                    setCustomerUser(data.UserCustomer);
                    setClientUser(data.UserClient);
                }
            });
        }
    }, [dispatch, vrid_i]);

    useEffect(() => {
        refreshOnlySchedule();
        getAssosData();
        refreshSheduleTable();
    }, [refreshSheduleTable, refreshOnlySchedule, getAssosData]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Visit Schedule | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Visit Schedule" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Visit", "Schedule", "Update"]} />
                    <div className="fulldetailsbody">
                        <form onSubmit={(event) => blankRequest(event)}>
                            <div className="toolbar-container">
                                <Link to={"/visit/request/view/" + vrid_i} className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                                {(intsta === "draft") ?
                                    <button type="button" onClick={newSchedule} className="my-button bg-lightgreen"><i className="far fa-calendar-check"></i> Complete</button>
                                    :
                                    <button type="button" onClick={newSchedule} className="my-button bg-lightgreen"><i className="far fa-calendar-check"></i> Update</button>
                                }
                                {(intsta === "draft") ? <button type="button" onClick={draftSchedule} className="my-button bg-skyblue"><i className="far fa-calendar"></i> Save As Draft</button> : ""}
                            </div>
                            <div className="myRow">

                                <div className="tab-container">
                                    <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Request Details</div>
                                    <div className={(tab === 2) ? "tab selected" : "tab"} onClick={() => setTab(2)}>Customer Details</div>
                                    <div className={(tab === 3) ? "tab selected" : "tab"} onClick={() => setTab(3)}>Member Details</div>
                                </div>
                                <div className="details-formcontainer big-panel">
                                    <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>

                                        <div className="myRow">
                                            <div className="col-md-12">
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Visit ID</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Visit ID" defaultValue={vrid_i} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Customer Name</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Customer Name" defaultValue={customername} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Request For</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Request For" defaultValue={requestfor} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Preferred Associate</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Preferred Associate" defaultValue={visitRequest.prefered_asso} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Requested Date</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Requested Date" defaultValue={visitRequest.visit_date} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Requested Time</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Requested Time" defaultValue={visitRequest.visit_time} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Service Type</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Service Type" defaultValue={visitRequest.servicetype} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Is this an Emergency?</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Is this an Emergency?" defaultValue={visitRequest.emergency} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Schedule Date</label>
                                                            <input className="input-cell" required type="date" placeholder="Enter Schedule Date" defaultValue={sh_date} onChange={(e) => setSh_date(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Schedule Time</label>
                                                            <input className="input-cell" required type="time" placeholder="Enter Schedule Time" defaultValue={sh_time} onChange={(e) => setSh_time(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Select Associate</label>
                                                            <select className="input-cell" onChange={(e) => getAsso(e)} required value={assoid}>
                                                                <option value="" disabled="">Select any Associate</option>
                                                                {
                                                                    (assouser.length !== 0) ?
                                                                        assouser.map((asso, index) =>
                                                                            <option key={index} value={asso.member_id}>{asso.fname} {asso.mname} {asso.lname}</option>
                                                                        ) :
                                                                        ""
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Note</label>
                                                            <textarea className="input-cell" placeholder="Enter Note" onChange={(e) => setNote(e.target.value)} defaultValue={note}>
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(tab === 2) ? "tab-details selected-tab" : "tab-details"}>
                                        {(customeruser !== undefined && customeruser !== null) ?
                                            <>
                                                <div className="myRow profile-backgound">
                                                    <div className="fullpofile-image">
                                                        <div className="profile-pic-cont">
                                                            <img src={customeruser.gender === "female" ? women_client : men_client} alt="Profile Pic" className="profile-upimg" />
                                                        </div>
                                                        <div className="member-id-text">{(customeruser.customer_cid !== undefined) ? customeruser.customer_cid : "-"}</div>
                                                        <Link to={"/customers/view/" + customeruser.customer_cid} className="my-button bg-lightblue spc-button"> <i className="fas fa-house-user"></i> View Customer Profile </Link>
                                                    </div>
                                                    <div className="profile-details">
                                                        <div className="profile-item">
                                                            <label>Name</label>
                                                            {(customeruser.fname !== undefined) ? customeruser.fname : "-"} {(customeruser.mname !== undefined) ? customeruser.mname : "-"}  {(customeruser.lname !== undefined) ? customeruser.lname : "-"}
                                                        </div>
                                                        <div className="profile-item">
                                                            <label>Email</label>
                                                            {(customeruser.email !== undefined) ? customeruser.email : "-"}
                                                        </div>
                                                        <div className="myRow">
                                                            <div className="col-md-6">
                                                                <div className="profile-item">
                                                                    <label>Address</label>
                                                                    {(customeruser.fladdress !== undefined) ? customeruser.fladdress : "-"}<br />
                                                                    {(customeruser.sladdress !== undefined) ? customeruser.sladdress : "-"}<br />
                                                                    {(customeruser.state !== undefined) ? customeruser.state : "-"}, {(customeruser.city !== undefined) ? customeruser.city : "-"}<br />
                                                                    Zip Code - {(customeruser.zipcode !== undefined) ? customeruser.zipcode : "-"}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-item">
                                                                    <label>Country</label>
                                                                    {(customeruser.country !== undefined) ? customeruser.country : "-"}
                                                                </div>
                                                                <div className="profile-item">
                                                                    <label>Locality  & Time Zone</label>
                                                                    {(customeruser.locality !== undefined) ? customeruser.locality : "-"} ( {(customeruser.timezone !== undefined) ? customeruser.timezone : "-"} )
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow padding-l-med">
                                                    <div className="col-md-12">
                                                        <div className="details-table">
                                                            <table className="table_inner">
                                                                <thead className="my_head">
                                                                    <tr>
                                                                        <td className="normal_td table_blue">Type</td>
                                                                        <td className="normal_td table_blue">Phone</td>
                                                                        <td className="normal_td table_blue">WhatsApp</td>
                                                                        <td className="normal_td table_blue cell-w768-nodisplay">Email</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="my_body">
                                                                    {

                                                                        (customeruser.contact.length > 0) ?
                                                                            customeruser.contact.map((contact, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{contact.contact_type}</td>
                                                                                    <td>{contact.phone}</td>
                                                                                    <td>{contact.whatsapp}</td>
                                                                                    <td className="cell-w768-nodisplay">{contact.email}</td>
                                                                                </tr>
                                                                            ) :
                                                                            <tr className="no-contact-data">
                                                                                <td colSpan={4} className="errordata">No Contact Added</td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-8">
                                                    <div className="no-data">
                                                        Customer Data Not Found
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className={(tab === 3) ? "tab-details selected-tab" : "tab-details"}>
                                        {(clientuser !== undefined && clientuser !== null) ?
                                            <>
                                                <div className="myRow profile-backgound">
                                                    <div className="fullpofile-image">
                                                        <div className="profile-pic-cont">
                                                            <img src={clientuser.gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-upimg" />
                                                        </div>
                                                        <div className="member-id-text">{(clientuser.client_id !== undefined) ? clientuser.client_id : "-"}</div>
                                                        <Link to={"/clients/view/" + clientuser.client_id} className="my-button bg-lightgreen spc-button"> <i className="fas fa-users"></i> View Member Profile </Link>
                                                    </div>
                                                    <div className="profile-details">
                                                        <div className="profile-item">
                                                            <label>Name</label>
                                                            {(clientuser.fname !== undefined) ? clientuser.fname : "-"} {(clientuser.mname !== undefined) ? clientuser.mname : "-"}  {(clientuser.lname !== undefined) ? clientuser.lname : "-"}
                                                        </div>
                                                        <div className="profile-item">
                                                            <label>Email</label>
                                                            {(clientuser.email !== undefined) ? clientuser.email : "-"}
                                                        </div>
                                                        <div className="myRow">
                                                            <div className="col-md-6">
                                                                <div className="profile-item">
                                                                    <label>Address</label>
                                                                    {(clientuser.fladdress !== undefined) ? clientuser.fladdress : "-"}<br />
                                                                    {(clientuser.sladdress !== undefined) ? clientuser.sladdress : "-"}<br />
                                                                    {(clientuser.state !== undefined) ? clientuser.state : "-"}, {(clientuser.city !== undefined) ? clientuser.city : "-"}<br />
                                                                    Zip Code - {(clientuser.zipcode !== undefined) ? clientuser.zipcode : "-"}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-item">
                                                                    <label>Country</label>
                                                                    {(clientuser.country !== undefined) ? clientuser.country : "-"}
                                                                </div>
                                                                <div className="profile-item">
                                                                    <label>Locality  & Time Zone</label>
                                                                    {(clientuser.locality !== undefined) ? clientuser.locality : "-"} ( {(clientuser.timezone !== undefined) ? clientuser.timezone : "-"} )
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow padding-l-med">
                                                    <div className="col-md-12">
                                                        <div className="details-table">
                                                            <table className="table_inner">
                                                                <thead className="my_head">
                                                                    <tr>
                                                                        <td className="normal_td table_blue">Type</td>
                                                                        <td className="normal_td table_blue">Phone</td>
                                                                        <td className="normal_td table_blue">WhatsApp</td>
                                                                        <td className="normal_td table_blue cell-w768-nodisplay">Email</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="my_body">
                                                                    {
                                                                        (clientuser.contact.length > 0) ?
                                                                            clientuser.contact.map((contact, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{contact.contact_type}</td>
                                                                                    <td>{contact.phone}</td>
                                                                                    <td>{contact.whatsapp}</td>
                                                                                    <td className="cell-w768-nodisplay">{contact.email}</td>
                                                                                </tr>
                                                                            ) :
                                                                            <tr className="no-contact-data">
                                                                                <td colSpan={4} className="errordata">No Contact Added</td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-8">
                                                    <div className="no-data">
                                                        Member Not Linked<br />
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                visitRequestLoading ? <Loading Type="fourdots" /> : ""
            }
            {
                loading ? <Loading Type="dna" /> : ""
            }
        </>
    );
}
export default UpdateVisitSchedule;