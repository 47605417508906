import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllPreferedCare = createAsyncThunk(
    "admin/getprefered",
    async () => {
        let result = await fetch(serverHost + "api/admin/getprefered", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addPreferedCare = createAsyncThunk(
    'admin/addprefered',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/addprefered", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });

export const updatePreferedCare = createAsyncThunk(
    'admin/updateprefered',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/updateprefered", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });
export const deletePreferedCare = createAsyncThunk(
    'admin/deleteprefered',
    async (dataid) => {
        let result = await fetch(serverHost + "api/admin/deleteprefered/" + dataid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });


export const getAllTaskSta = createAsyncThunk(
    "admin/gettasksta",
    async () => {
        let result = await fetch(serverHost + "api/admin/gettasksta", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addTaskSta = createAsyncThunk(
    'admin/addtasksta',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/addtasksta", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });

export const updateTaskSta = createAsyncThunk(
    'admin/updatetasksta',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/updatetasksta", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });
export const deleteTaskSta = createAsyncThunk(
    'admin/deletetasksta',
    async (dataid) => {
        let result = await fetch(serverHost + "api/admin/deletetasksta/" + dataid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });


export const getAllRelation = createAsyncThunk(
    "admin/getrelation",
    async () => {
        let result = await fetch(serverHost + "api/admin/getrelation", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addRelation = createAsyncThunk(
    'admin/addrelation',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/addrelation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });

export const updateRelation = createAsyncThunk(
    'admin/updaterelation',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/updaterelation", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });
export const deleteRelation = createAsyncThunk(
    'admin/deleterelation',
    async (dataid) => {
        let result = await fetch(serverHost + "api/admin/deleterelation/" + dataid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });

export const getAllPlan = createAsyncThunk(
    "admin/getplan",
    async () => {
        let result = await fetch(serverHost + "api/admin/getplan", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addPlan = createAsyncThunk(
    'admin/addplan',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/addplan", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });

export const updatePlan = createAsyncThunk(
    'admin/updateplan',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/updateplan", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });
export const deletePlan = createAsyncThunk(
    'admin/deleteplan',
    async (dataid) => {
        let result = await fetch(serverHost + "api/admin/deleteplan/" + dataid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });

export const getAllAnonymousRequests = createAsyncThunk(
    "admin/anonymousvisitrequests",
    async () => {
        let result = await fetch(serverHost + "api/admin/anonymousvisitrequests", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getAllAnonymousJoinRequests = createAsyncThunk(
    "admin/anonymousjoinrequests",
    async () => {
        let result = await fetch(serverHost + "api/admin/anonymousjoinrequests", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getAllService = createAsyncThunk(
    "admin/getservice",
    async () => {
        let result = await fetch(serverHost + "api/admin/getservice", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addService = createAsyncThunk(
    'admin/addservice',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/addservice", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });

export const updateService = createAsyncThunk(
    'admin/updateservice',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/updateservice", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });
export const deleteService = createAsyncThunk(
    'admin/deleteservice',
    async (dataid) => {
        let result = await fetch(serverHost + "api/admin/deleteservice/" + dataid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });

export const assignAsso = createAsyncThunk(
    'admin/anonymousvisitrequests/assign',
    async (yourData) => {
        let result = await fetch(serverHost + "api/admin/anonymousvisitrequests/assign", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(yourData)
        })
        result = await result.json();
        return result;
    });
export const getAllFileData = createAsyncThunk(
    "admin/getfilesdata",
    async () => {
        let result = await fetch(serverHost + "api/admin/getfilesdata", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

export const uploadData = createAsyncThunk(
    'client/uploadfile',
    async (joinData) => {
        let result = await fetch(serverHost + "api/client/uploadfile", {
            method: "POST",
            body: joinData
        })
        result = await result.json();
        return result;
    });

const adminFeatureSlice = createSlice({
    name: 'AdminFeature',
    initialState: {
        adminloading: false,
        admindata: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadData.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(uploadData.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(uploadData.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllFileData.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllFileData.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllFileData.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(assignAsso.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(assignAsso.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(assignAsso.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllService.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllService.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllService.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addService.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(addService.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(addService.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateService.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(updateService.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(updateService.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deleteService.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(deleteService.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(deleteService.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })

            .addCase(getAllAnonymousJoinRequests.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllAnonymousJoinRequests.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllAnonymousJoinRequests.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllPreferedCare.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllPreferedCare.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllPreferedCare.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllAnonymousRequests.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllAnonymousRequests.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllAnonymousRequests.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deletePreferedCare.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(deletePreferedCare.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(deletePreferedCare.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addPreferedCare.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(addPreferedCare.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(addPreferedCare.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updatePreferedCare.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(updatePreferedCare.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(updatePreferedCare.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })

            .addCase(getAllTaskSta.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllTaskSta.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllTaskSta.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addTaskSta.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(addTaskSta.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(addTaskSta.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateTaskSta.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(updateTaskSta.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(updateTaskSta.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deleteTaskSta.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(deleteTaskSta.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(deleteTaskSta.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })

            .addCase(getAllRelation.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllRelation.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllRelation.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addRelation.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(addRelation.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(addRelation.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateRelation.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(updateRelation.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(updateRelation.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deleteRelation.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(deleteRelation.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(deleteRelation.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })

            .addCase(getAllPlan.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(getAllPlan.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(getAllPlan.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addPlan.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(addPlan.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(addPlan.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updatePlan.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(updatePlan.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(updatePlan.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deletePlan.pending, (state) => {
                state.adminloading = true;
                state.admindata = null;
                state.error = null;
            })
            .addCase(deletePlan.fulfilled, (state, action) => {
                state.adminloading = false;
                state.admindata = action.payload;
                state.error = null;
            })
            .addCase(deletePlan.rejected, (state, action) => {
                state.adminloading = false;
                state.admindata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default adminFeatureSlice.reducer;