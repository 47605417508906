import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../detailsfield.css';
import TopNav from "../layout/TopNav";
import HeaderNav from "../layout/HeaderNav";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../Loading";
import SideMenu from "../layout/SideMenu";

function Chat() {

    const dispatch = useDispatch();
    const [mobNav, setMobNav] = useState(false);

    useEffect(() => {
        
    }, []);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Chat | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Chat" valueMobNav={mobNav} />
                <div className="right-total-body position-reative">
                    <HeaderNav navValue={["Chat", "All"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <button type="button" className="my-button bg-yellow"><i className="fas fa-undo"></i> New Chat</button>
                        </div>

                        <div className="myRow">
                            <div className="col-md-3 spc-mob-form">
                                
                                
                            </div>
                            <div className="col-md-9">
                                
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            
        </>
    )
}
export default Chat;
