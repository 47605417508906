import { configureStore } from "@reduxjs/toolkit";
import memberReducer from "../redux/memberAuth";
import memberDetailsReducer from "../redux/memberDetails";
import customerDetailsReducer from "../redux/customerDetails";
import clientDetailsReducer from "../redux/clientDetails";
import visitScheduleReducer from "../redux/visitSchedule";
import visitReportReducer from "../redux/visitReport";
import visitRequestReducer from "../redux/visitRequest";
import visitPaymentReducer from "../redux/visitPayment";
import adminFeatureReducer from "../redux/adminFeature";

const store = configureStore({
    reducer:{
        member : memberReducer,
        membersDetails : memberDetailsReducer,
        customerDetails : customerDetailsReducer,
        clientDetails : clientDetailsReducer,
        VisitSchedule : visitScheduleReducer,
        VisitReport : visitReportReducer,
        VisitRequest: visitRequestReducer,
        VisitPayment: visitPaymentReducer,
        AdminFeature: adminFeatureReducer
    }
});

export default store;