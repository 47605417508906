import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import '../../css/Client.css';
import '../detailsfield.css';
import '../formfield.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import men_member from '../../img/customer-men.png';
import women_member from '../../img/customer-women.png';
import men_client from '../../img/client-men.png';
import women_client from '../../img/client-women.png';
import { getFullVisitDetails } from "../../redux/visitRequest";
import { edituploadFile, getvisitReportdetails, updateReport } from "../../redux/visitReport";
import { getAllTaskSta } from "../../redux/adminFeature";

function UpdateVisitReport() {


    const [customeruser, setCustomerUser] = useState();
    const [clientuser, setClientUser] = useState();

    const [vrid, setVrid] = useState("");
    const [vsid, setVsid] = useState("");
    const [presta, setPresta] = useState("");
    const [visitreport_id, setVReportID] = useState("");
    const [reportdetails, setReportdetails] = useState("");
    const [vsdate, setVSDATE] = useState("");
    const [vedate, setVEDATE] = useState("");
    const [sh_date, setSh_date] = useState("");
    const [sh_time, setSh_time] = useState("");
    const [customername, setCustomername] = useState("");
    const [requestfor, setRequestfor] = useState("");

    const [visitTask, setVisitTask] = useState([]);
    const [deleteTask, setdeleteTask] = useState([]);
    const [vperby, setVperby] = useState("");
    const [vperbyid, setVperById] = useState("");
    const [files, setFile] = useState([]);
    const [oldfiles, setOldFile] = useState([]);

    const [task, setreTask] = useState("");
    const [taskstatus, setTaskSta] = useState("");
    const [persentage, setTaskpre] = useState("");
    const [tasknote, setTaskNote] = useState("");
    const [taskind, setTaskind] = useState(0);
    const [taskbut, setTaskAdd] = useState(true);

    const [taskstaData, setTaskStaData] = useState([]);


    let status;

    let member_id = JSON.parse(localStorage.getItem('member-info')).member_id;
    let member = JSON.parse(localStorage.getItem('member-info'));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { visitRequestLoading } = useSelector((state) => state.VisitRequest);
    const { visitReportLoading } = useSelector((state) => state.VisitReport);

    const [tab, setTab] = useState(1);
    const [mobNav, setMobNav] = useState(false);

    let { reportId } = useParams();

    function blankRequest(event) {
        event.preventDefault();
    };
    function newReport() {
        status = "complete";
        updateVisitReport();
    };
    function draftReport() {
        status = "draft";
        updateVisitReport();
    };

    function addTask() {
        if (task !== "" && taskstatus !== "" && persentage !== "") {
            let id = "0";
            let vrid = "0";
            let created_at = "0";
            let updated_at = "0";
            let taskData = { id, vrid, task, taskstatus, persentage, tasknote, created_at, updated_at };
            let t = [...visitTask];;
            t.push(taskData);
            setVisitTask(t);
            document.getElementById("addTaskF").reset();
            setreTask("");
            setTaskSta("");
            setTaskpre("");
            setTaskNote("");
            setTaskAdd(true);
        }

    };
    function updateTask() {
        if (task !== "" && taskstatus !== "" && persentage !== "") {
            let t = [...visitTask];
            let id = t[taskind].id;
            let vrid = t[taskind].vrid;
            let created_at = t[taskind].created_at;
            let updated_at = t[taskind].updated_at;
            let taskData = { id, vrid, task, taskstatus, persentage, tasknote, created_at, updated_at };
            t[taskind] = taskData;
            setVisitTask(t);
            document.getElementById("addTaskF").reset();
            setreTask("");
            setTaskSta("");
            setTaskpre("");
            setTaskNote("");
            setTaskAdd(true);
        }
    };
    function editTask(inde) {
        setreTask(visitTask[inde].task);
        setTaskSta(visitTask[inde].taskstatus);
        setTaskpre(visitTask[inde].persentage);
        setTaskNote(visitTask[inde].tasknote);
        setTaskAdd(false);
        setTaskind(inde);
    }
    function removeTask(inde) {
        let t = visitTask.slice(0, inde);
        let k = visitTask.slice(inde + 1, visitTask.length);
        let f = deleteTask;
        let df = visitTask[inde];
        setVisitTask(t.concat(k));
        if (f.length === 0) {
            f[0] = df;
        } else {
            f.push(df);
        }
        setdeleteTask(f);
    }

    function updateVisitReport() {
        let scheduleData = { visitreport_id, vrid, vsid, vsdate, vedate, visitTask, status, vperbyid, vperby, deleteTask, reportdetails };
        dispatch(updateReport(scheduleData)).then((result) => {
            const formData = new FormData();
            formData.append("vreportid", visitreport_id);
            for (let i = 0; i < files.length; i++) {
                formData.append('files[]', files[i]);
            }
            for (let i = 0; i < oldfiles.length; i++) {
                formData.append('oldfiles[]', oldfiles[i]);
            }
            dispatch(edituploadFile(formData)).then((result) => {
                if (result.payload.error === "") {
                    navigate("/visit/report/view/" + vrid);
                }
            });
        });
    }

    function updatePer(data) {
        setTaskSta(data);
        const found = taskstaData.find(obj => {
            return obj.taskname === data;
        });
        setTaskpre(found.persentage);
    }

    function setFileData(data) {
        if (data !== undefined) {
            let my = [...files];
            my.push(data);
            setFile(my);
        }
    }

    function removeFile(inde) {
        let t = files.slice(0, inde);
        let k = files.slice(inde + 1, files.length);
        setFile(t.concat(k));
    }
    function removeOldFile(inde) {
        let t = oldfiles.slice(0, inde);
        let k = oldfiles.slice(inde + 1, oldfiles.length);
        setOldFile(t.concat(k));
    }

    const getTaskStatus = useCallback(() => {
        dispatch(getAllTaskSta()).then((result) => {
            if (result.payload.error === "") {
                setTaskStaData(result.payload.data);
            }

        });
    }, [dispatch]);

    const refreshOnlyReport = useCallback(() => {

        dispatch(getvisitReportdetails(reportId)).then((result) => {
            if (result.payload.error === "") {
                let data = result.payload.data;
                setVsid(data.vsid);
                setVrid(data.vrid);
                setVSDATE(data.vsdate);
                setVEDATE(data.vedate);
                status = data.status;
                setVReportID(reportId);
                setPresta(data.status);
                setReportdetails(data.reportdetails);
                setOldFile(data.attachment.split("$;"));
            }
        });


    }, [dispatch, reportId]);

    const refreshSheduleTable = useCallback(() => {
        if (vrid !== "") {
            dispatch(getFullVisitDetails(vrid)).then((result) => {
                if (result.payload.error === "") {
                    let data = result.payload;
                    setCustomerUser(data.UserCustomer);
                    setClientUser(data.UserClient);
                    setSh_date(data.VisitSchedule.sh_date);
                    setSh_time(data.VisitSchedule.sh_time);
                    setCustomername(data.VisitSchedule.customername);
                    setRequestfor(data.VisitSchedule.requestfor);
                    let coname = member.fname + " ";
                    coname += (member.mname != null) ? member.mname + " " : "";
                    coname += member.lname;
                    setVperby(coname);
                    setVperById(member_id);
                    setVisitTask(data.VisitTasks);
                }
            });
        }
    }, [dispatch, vrid, member.fname, member.mname, member.lname, member_id]);

    useEffect(() => {
        refreshOnlyReport();
        refreshSheduleTable();
        getTaskStatus();
    }, [refreshSheduleTable, refreshOnlyReport, getTaskStatus]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Visit Report | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Visit Report" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Visit", "Report", "New"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to={"/visit/report/view/" + vrid} className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                            <button type="button" onClick={newReport} className="my-button bg-lightgreen"><i className="fas fa-tasks"></i> Submit Report</button>
                            {
                                (presta !== "draft") ? "" :
                                    <button type="button" onClick={draftReport} className="my-button bg-skyblue"><i className="fas fa-project-diagram"></i> Save As Draft</button>
                            }
                        </div>
                        <div className="myRow">

                            <div className="tab-container">
                                <div className={(tab === 1) ? "tab selected" : "tab"} onClick={() => setTab(1)}>Report Details</div>
                                <div className={(tab === 2) ? "tab selected" : "tab"} onClick={() => setTab(2)}>Customer Details</div>
                                <div className={(tab === 3) ? "tab selected" : "tab"} onClick={() => setTab(3)}>Member Details</div>
                            </div>
                            <div className="details-formcontainer big-panel">
                                <div className={(tab === 1) ? "tab-details selected-tab" : "tab-details"}>

                                    <div className="myRow">
                                        <div className="col-md-12">
                                            <form onSubmit={(event) => blankRequest(event)}>
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Visit ID</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Visit ID" defaultValue={vrid} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Schedule ID</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Visit Schedule ID" defaultValue={vsid} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Visit Performed By</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Visit Schedule ID" defaultValue={vperby} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Customer Name</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Customer Name" defaultValue={customername} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Request For</label>
                                                            <input className="input-cell" readOnly type="text" placeholder="Enter Request For" defaultValue={requestfor} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Schedule Date</label>
                                                            <input className="input-cell" readOnly required type="date" placeholder="Enter Schedule Date" defaultValue={sh_date} onChange={(e) => setSh_date(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Schedule Time</label>
                                                            <input className="input-cell" readOnly required type="time" placeholder="Enter Schedule Time" defaultValue={sh_time} onChange={(e) => setSh_time(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Visit Start Date & Time</label>
                                                            <input className="input-cell" required type="datetime-local" placeholder="Enter Start Date and Time" defaultValue={vsdate} onChange={(e) => setVSDATE(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Visit End Date & Time</label>
                                                            <input className="input-cell" required type="datetime-local" placeholder="Enter End Date and Time" defaultValue={vedate} onChange={(e) => setVEDATE(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Visit Report Details</label>
                                                            <textarea className="input-cell" defaultValue={reportdetails} onChange={(e) => setReportdetails(e.target.value)} placeholder="Enter Start Date and Time" >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="file-upload-cont">
                                                            <input type="file" accept=".pdf,.doc,.docx,.jpg,.png" onChange={(e) => setFileData(e.target.files[0])} />
                                                            <div className="file-label">
                                                                <div><i className="fas fa-cloud-upload-alt"></i></div>
                                                                <div>Click to Upload</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label className="input-label-file">Old File</label>
                                                        <div className="file-list">
                                                            {
                                                                (oldfiles.length > 0) ?
                                                                    oldfiles.map((file, index) =>
                                                                        (file !== "") ?
                                                                            <div className="file-item" key={index}>
                                                                                <div className="file-type">
                                                                                    {file.split(".")[1]}
                                                                                </div>
                                                                                <div className="file-name">
                                                                                    {file.split(".")[0].split("_")[0]}
                                                                                </div>
                                                                                <div className="file-remove" onClick={() => removeOldFile(index)}>
                                                                                    <i className="far fa-times-circle"></i>
                                                                                </div>
                                                                            </div> : ""
                                                                    ) : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label className="input-label-file">New File</label>
                                                        <div className="file-list">
                                                            {
                                                                (files.length > 0) ?
                                                                    files.map((file, index) =>
                                                                        <div className="file-item" key={index}>
                                                                            <div className="file-type">
                                                                                {file.name.split(".")[1]}
                                                                            </div>
                                                                            <div className="file-name">
                                                                                {file.name.split(".")[0]}
                                                                            </div>
                                                                            <div className="file-remove" onClick={() => removeFile(index)}>
                                                                                <i className="far fa-times-circle"></i>
                                                                            </div>
                                                                        </div>
                                                                    ) : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <form onSubmit={(event) => blankRequest(event)} id="addTaskF">
                                                <div className="myRow">
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Requestetd Task</label>
                                                            <input className="input-cell" type="text" placeholder="Enter Requestetd Task" defaultValue={task} onChange={(e) => setreTask(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Task Status</label>
                                                            <select className="input-cell" onChange={(e) => updatePer(e.target.value)} value={taskstatus}>
                                                                <option value={""} disabled>Select any One</option>
                                                                {
                                                                    (taskstaData.length !== 0) ?
                                                                        taskstaData.map((taskts, index) =>
                                                                            <option key={index} value={taskts.taskname}>{taskts.taskname}</option>
                                                                        ) :
                                                                        ""
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Task Percentage</label>
                                                            <input className="input-cell" type="number" placeholder="Enter Task Percentage" defaultValue={persentage} onChange={(e) => setTaskpre(e.target.value)} min={0} max={100} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-formgroup">
                                                            <label className="input-label">Task Note</label>
                                                            <input className="input-cell" type="text" placeholder="Enter Task Note" defaultValue={tasknote} onChange={(e) => setTaskNote(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="myRow justify-content-end padding-r-med">
                                                <button type="button" onClick={() => addTask()} className="my-button bg-lightgreen"><i className="fas fa-plus"></i> Add Task</button>
                                                {
                                                    (taskbut) ?
                                                        ""
                                                        :
                                                        <button type="button" onClick={() => updateTask()} className="my-button bg-spblue"><i className="fas fa-pencil-alt"></i> Edit Task</button>
                                                }
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="details-table">
                                                        <table className="table_inner">
                                                            <thead className="my_head">
                                                                <tr>
                                                                    <td className="normal_td table_blue mob-td-minwidth75">Action</td>
                                                                    <td className="normal_td table_blue">Requestetd Task</td>
                                                                    <td className="normal_td table_blue">Task Status</td>
                                                                    <td className="normal_td table_blue">Task Percentage</td>
                                                                    <td className="normal_td table_blue">Task Note</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="my_body">
                                                                {
                                                                    (visitTask.length > 0) ?
                                                                        visitTask.map((task, index) =>
                                                                            <tr key={index}>
                                                                                <td className="icon-tab">
                                                                                    <i className="fas fa-pencil-alt" onClick={() => editTask(index)}></i>
                                                                                    <i className="far fa-trash-alt" onClick={() => removeTask(index)}></i>
                                                                                </td>
                                                                                <td>{task.task}</td>
                                                                                <td className="startwordcapital">{task.taskstatus}</td>
                                                                                <td>{task.persentage} %</td>
                                                                                <td>{task.tasknote}</td>
                                                                            </tr>
                                                                        ) :
                                                                        <tr className="no-contact-data">
                                                                            <td colSpan={5} className="errordata">No Data Added</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={(tab === 2) ? "tab-details selected-tab" : "tab-details"}>
                                    {(customeruser !== undefined && customeruser !== null) ?
                                        <>
                                            <div className="myRow profile-backgound">
                                                <div className="fullpofile-image">
                                                    <div className="profile-pic-cont">
                                                        <img src={customeruser.gender === "female" ? women_client : men_client} alt="Profile Pic" className="profile-upimg" />
                                                    </div>
                                                    <div className="member-id-text">{(customeruser.customer_cid !== undefined) ? customeruser.customer_cid : "-"}</div>
                                                    <Link to={"/customers/view/" + customeruser.customer_cid} className="my-button bg-lightblue spc-button"> <i className="fas fa-house-user"></i> View Customer Profile </Link>
                                                </div>
                                                <div className="profile-details">
                                                    <div className="profile-item">
                                                        <label>Name</label>
                                                        {(customeruser.fname !== undefined) ? customeruser.fname : "-"} {(customeruser.mname !== undefined) ? customeruser.mname : "-"}  {(customeruser.lname !== undefined) ? customeruser.lname : "-"}
                                                    </div>
                                                    <div className="profile-item">
                                                        <label>Email</label>
                                                        {(customeruser.email !== undefined) ? customeruser.email : "-"}
                                                    </div>
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Address</label>
                                                                {(customeruser.fladdress !== undefined) ? customeruser.fladdress : "-"}<br />
                                                                {(customeruser.sladdress !== undefined) ? customeruser.sladdress : "-"}<br />
                                                                {(customeruser.state !== undefined) ? customeruser.state : "-"}, {(customeruser.city !== undefined) ? customeruser.city : "-"}<br />
                                                                Zip Code - {(customeruser.zipcode !== undefined) ? customeruser.zipcode : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Country</label>
                                                                {(customeruser.country !== undefined) ? customeruser.country : "-"}
                                                            </div>
                                                            <div className="profile-item">
                                                                <label>Locality  & Time Zone</label>
                                                                {(customeruser.locality !== undefined) ? customeruser.locality : "-"} ( {(customeruser.timezone !== undefined) ? customeruser.timezone : "-"} )
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="details-table">
                                                        <table className="table_inner">
                                                            <thead className="my_head">
                                                                <tr>
                                                                    <td className="normal_td table_blue">Type</td>
                                                                    <td className="normal_td table_blue">Phone</td>
                                                                    <td className="normal_td table_blue">WhatsApp</td>
                                                                    <td className="normal_td table_blue cell-w768-nodisplay">Email</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="my_body">
                                                                {

                                                                    (customeruser.contact.length > 0) ?
                                                                        customeruser.contact.map((contact, index) =>
                                                                            <tr key={index}>
                                                                                <td>{contact.contact_type}</td>
                                                                                <td>{contact.phone}</td>
                                                                                <td>{contact.whatsapp}</td>
                                                                                <td className="cell-w768-nodisplay">{contact.email}</td>
                                                                            </tr>
                                                                        ) :
                                                                        <tr className="no-contact-data">
                                                                            <td colSpan={4} className="errordata">No Contact Added</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="no-data">
                                                    Customer Data Not Found
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <div className={(tab === 3) ? "tab-details selected-tab" : "tab-details"}>
                                    {(clientuser !== undefined && clientuser !== null) ?
                                        <>
                                            <div className="myRow profile-backgound">
                                                <div className="fullpofile-image">
                                                    <div className="profile-pic-cont">
                                                        <img src={clientuser.gender === "female" ? women_member : men_member} alt="Profile Pic" className="profile-upimg" />
                                                    </div>
                                                    <div className="member-id-text">{(clientuser.client_id !== undefined) ? clientuser.client_id : "-"}</div>
                                                    <Link to={"/clients/view/" + clientuser.client_id} className="my-button bg-lightgreen spc-button"> <i className="fas fa-users"></i> View Member Profile </Link>
                                                </div>
                                                <div className="profile-details">
                                                    <div className="profile-item">
                                                        <label>Name</label>
                                                        {(clientuser.fname !== undefined) ? clientuser.fname : "-"} {(clientuser.mname !== undefined) ? clientuser.mname : "-"}  {(clientuser.lname !== undefined) ? clientuser.lname : "-"}
                                                    </div>
                                                    <div className="profile-item">
                                                        <label>Email</label>
                                                        {(clientuser.email !== undefined) ? clientuser.email : "-"}
                                                    </div>
                                                    <div className="myRow">
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Address</label>
                                                                {(clientuser.fladdress !== undefined) ? clientuser.fladdress : "-"}<br />
                                                                {(clientuser.sladdress !== undefined) ? clientuser.sladdress : "-"}<br />
                                                                {(clientuser.state !== undefined) ? clientuser.state : "-"}, {(clientuser.city !== undefined) ? clientuser.city : "-"}<br />
                                                                Zip Code - {(clientuser.zipcode !== undefined) ? clientuser.zipcode : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="profile-item">
                                                                <label>Country</label>
                                                                {(clientuser.country !== undefined) ? clientuser.country : "-"}
                                                            </div>
                                                            <div className="profile-item">
                                                                <label>Locality  & Time Zone</label>
                                                                {(clientuser.locality !== undefined) ? clientuser.locality : "-"} ( {(clientuser.timezone !== undefined) ? clientuser.timezone : "-"} )
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow padding-l-med">
                                                <div className="col-md-12">
                                                    <div className="details-table">
                                                        <table className="table_inner">
                                                            <thead className="my_head">
                                                                <tr>
                                                                    <td className="normal_td table_blue">Type</td>
                                                                    <td className="normal_td table_blue">Phone</td>
                                                                    <td className="normal_td table_blue">WhatsApp</td>
                                                                    <td className="normal_td table_blue cell-w768-nodisplay">Email</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="my_body">
                                                                {
                                                                    (clientuser.contact.length > 0) ?
                                                                        clientuser.contact.map((contact, index) =>
                                                                            <tr key={index}>
                                                                                <td>{contact.contact_type}</td>
                                                                                <td>{contact.phone}</td>
                                                                                <td>{contact.whatsapp}</td>
                                                                                <td className="cell-w768-nodisplay">{contact.email}</td>
                                                                            </tr>
                                                                        ) :
                                                                        <tr className="no-contact-data">
                                                                            <td colSpan={4} className="errordata">No Contact Added</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <div className="myRow padding-l-med">
                                            <div className="col-md-8">
                                                <div className="no-data">
                                                    Member Not Linked<br />
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {
                visitRequestLoading ? <Loading Type="fourdots" /> : ""
            }
            {
                visitReportLoading ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default UpdateVisitReport;