import { Link, useNavigate } from 'react-router-dom';
import adminprofile from '../../img/TestAdmin.png';
import { useState } from 'react';
function TopNav(props) {

    let valueMobNav = props.valueMobNav;
    let onMobNav = props.onMobNav;

    const [menupopup,setMenuPopup]=useState(false);

    function toggleNav() {
        onMobNav(!valueMobNav);
    }
    function offNav() {
        onMobNav(false);
    }
    const navigate = useNavigate();

    let firstName = JSON.parse(localStorage.getItem('member-info')).fname;
    let lastName = JSON.parse(localStorage.getItem('member-info')).lname;
    let role = JSON.parse(localStorage.getItem('member-info')).role;
    function getGreet() {
        const currTime = new Date();
        let greet = "";
        if (currTime.getHours() < 6) {
            greet = "Night";
        } else if (currTime.getHours() < 12) {
            greet = "Morning";
        } else if (currTime.getHours() < 17) {
            greet = "AfterNoon";
        } else if (currTime.getHours() < 19) {
            greet = "Evening";
        } else {
            greet = "Night";
        }
        return greet;
    };
    const logout = () => {
        localStorage.clear();
        navigate("/login");
    }

    return (
        <div className="topnav">
            <div className="mob-nav-toggle" onClick={()=>[toggleNav(),setMenuPopup(false)]}>
                <i className="fas fa-bars"></i>
            </div>
            <div className="greetType">
                <span>Welcome</span>, {firstName}<br />
                Good <span>{getGreet()}</span>
            </div>
            <div className="rightPart">
                <div className="optionItem">
                    <Link to={"/chat"}>
                    <div className="chat-icon">
                        <i className="fas fa-comment-dots"></i>
                        <span className="mark-num"></span>
                    </div>
                    </Link>
                </div>
                <div className="optionItem">
                    <div className="notification-icon">
                        <i className="fas fa-bell"></i>
                        <span className="mark-num"></span>
                    </div>
                </div>
                <div className="optionItem" onClick={() =>[setMenuPopup(!menupopup),offNav()]}>
                    <img src={adminprofile} alt="Profile Pic" className="profileimg" />
                </div>
            </div>
            <div className={menupopup?"popupcontainter popupshow":"popupcontainter"}>
                <div className='popupbody'>
                    {firstName} {lastName}<br/>
                    <span>({role})</span>
                </div>
                <div className='popupfooter'>
                    <Link to="/profile" className='popupbut' onClick={()=>setMenuPopup(!menupopup)}>Profile</Link>
                    <div className='popupbut' onClick={logout}>Logout</div>
                </div>
            </div>
        </div>
    );
}
export default TopNav;