import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllClients = createAsyncThunk(
    'client/getAllClients',
    async () => {
        let result = await fetch(serverHost + "api/client/getallclients", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addClient = createAsyncThunk(
    'client/addnewclient',
    async (clientData) => {
        let result = await fetch(serverHost + "api/client/addnewclient", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(clientData)
        })
        result = await result.json();
        return result;
    });
export const getclientdetails = createAsyncThunk(
    'client/getclientdetails',
    async (clientId) => {
        let result = await fetch(serverHost + "api/client/getclientdetails/" + clientId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addClientContact = createAsyncThunk(
    'client/addcontact',
    async (yourData) => {
        let clientId = yourData.cid;
        let contactDatas = yourData.contactData;
        let result = await fetch(serverHost + "api/client/addcontact/" + clientId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const getClientContacts = createAsyncThunk(
    "client/getcontacts",
    async (clientId) => {
        let result = await fetch(serverHost + "api/client/getcontact/" + clientId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const updateClientContact = createAsyncThunk(
    'client/updatecontact',
    async (contactData) => {
        let contactDatas = contactData;
        let result = await fetch(serverHost + "api/client/updatecontact", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const deleteClientContact = createAsyncThunk(
    'client/contact/delete',
    async (contact_id) => {
        let contactid = contact_id;
        let result = await fetch(serverHost + "api/client/deletecontact/" + contactid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });
export const resetClientPassword = createAsyncThunk(
    'client/resetpassword',
    async (clientId) => {
        let password = { "reset_password": "Password" };
        let result = await fetch(serverHost + "api/client/resetpassword/" + clientId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(password)
        })
        result = await result.json();
        return result;
    });
export const editClient = createAsyncThunk(
    'client/updateclient',
    async (clientData) => {
        let customerDatas = clientData;
        let result = await fetch(serverHost + "api/client/updateclient", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(customerDatas)
        })
        result = await result.json();
        return result;
    });

const clientDetailsSlice = createSlice({
    name: 'ClientDetails',
    initialState: {
        clientloading: false,
        client: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllClients.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(getAllClients.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(getAllClients.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addClient.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(addClient.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(addClient.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getclientdetails.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(getclientdetails.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(getclientdetails.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deleteClientContact.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(deleteClientContact.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(deleteClientContact.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateClientContact.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(updateClientContact.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(updateClientContact.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getClientContacts.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(getClientContacts.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(getClientContacts.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addClientContact.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(addClientContact.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(addClientContact.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(editClient.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(editClient.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(editClient.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(resetClientPassword.pending, (state) => {
                state.clientloading = true;
                state.client = null;
                state.error = null;
            })
            .addCase(resetClientPassword.fulfilled, (state, action) => {
                state.clientloading = false;
                state.client = action.payload;
                state.error = null;
            })
            .addCase(resetClientPassword.rejected, (state, action) => {
                state.clientloading = false;
                state.client = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default clientDetailsSlice.reducer;