import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { useState } from "react";
import { Link } from "react-router-dom";

function ReferenceData() {

    const [mobNav, setMobNav] = useState(false);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Reference Data | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Reference Data" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Reference Data"]} />
                    <div className="fulldetailsbody">
                        <div className="myRow card-container">
                            <div class="details-title">Reference Data</div>
                            <div className="col-md-3 card-lightred">
                                <div className="card-single">
                                    <div className="card-logo"><i className="fas fa-dove"></i></div>
                                    <div className="card-title">Relationship</div>
                                    <Link to={"/admin/relationship"} className="card-button bg-lightred">View</Link>
                                </div>
                            </div>
                            <div className="col-md-3 card-lightgreen">
                                <div className="card-single">
                                    <div className="card-logo"><i className="fas fa-thumbtack"></i></div>
                                    <div className="card-title">Task Status</div>
                                    <Link to={"/admin/taskstatus"} className="card-button bg-lightgreen">View</Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card-single card-lightblue">
                                    <div className="card-logo"><i className="far fa-hospital"></i></div>
                                    <div className="card-title">Home & Hosplital</div>
                                    <Link to={"/admin/home"} className="card-button bg-lightblue">View</Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card-single card-deeporange">
                                    <div className="card-logo"><i className="fas fa-concierge-bell"></i></div>
                                    <div className="card-title">Service Type</div>
                                    <Link to={"/admin/service"} className="card-button bg-deeporange">View</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ReferenceData;