import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getAllRequest } from "../../redux/visitRequest";

function VisitListRequest() {

    const [visitRequest, setVisitListRequest] = useState([]);

    const dispatch = useDispatch();

    const { visitRequestLoading } = useSelector((state) => state.VisitRequest);

    const [mobNav, setMobNav] = useState(false);
    
    let status = "in progress";

    const refreshSheduleTable = useCallback(() => {
        dispatch(getAllRequest(status)).then((result) => {
            if (result.payload.error === "") {
                setVisitListRequest(result.payload.data);
            }
        });
    }, [dispatch, status]);

    useEffect(() => {
        refreshSheduleTable();
    }, [refreshSheduleTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>All Visit Request | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Visit Schedule" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <HeaderNav navValue={["Visit", "Request"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to={"/visit/schedule"} className="my-button bg-yellow"><i className="fas fa-chevron-left"></i> Back</Link>
                        </div>
                        <div className="details-tabletitle">Visit Requests</div>
                        <div className="details-table">
                           
                            <table className="table_inner">
                                <thead className="my_head">
                                    <tr>
                                        <td className="normal_td table_blue">View</td>
                                        <td className="normal_td table_blue">Request ID</td>
                                        <td className="normal_td table_blue">Customer ID</td>
                                        <td className="normal_td table_blue">Customer Name</td>
                                        <td className="normal_td table_blue">Request For</td>
                                        <td className="normal_td table_blue">Emergency</td>
                                        <td className="normal_td table_blue">Status</td>
                                        <td className="normal_td table_blue">Visit Date & Time</td>
                                        <td className="normal_td table_blue">Service Type</td>
                                        <td className="normal_td table_blue">Prefered Associate</td>
                                    </tr>
                                </thead>
                                <tbody className="my_body">
                                    {
                                        (visitRequest.length !== 0) ?
                                            visitRequest.map((visit, index) =>
                                                <tr key={index}>
                                                    <td className="icon-tab"><Link to={"/visit/request/view/" + visit.vrid}><i className="far fa-eye"></i></Link></td>
                                                    <td>{visit.vrid}</td>
                                                    <td>{visit.customer_cid}</td>
                                                    <td>{visit.customerfname} {visit.customermname} {visit.customerlname}</td>
                                                    <td>{visit.request_for}</td>
                                                    <td className="startwordcapital">{visit.emergency}</td>
                                                    <td className="startwordcapital">{visit.status}</td>
                                                    <td>{visit.visit_date} - {visit.visit_time}</td>
                                                    <td>{visit.servicetype}</td>
                                                    <td>{visit.prefered_asso}</td>
                                                </tr>
                                            ) :
                                            <tr className="no-contact-data">
                                                <td colSpan={10} className="errordata">No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                visitRequestLoading ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default VisitListRequest;