import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllCustomers = createAsyncThunk(
    'customer/getAllCustomers',
    async () => {
        let result = await fetch(serverHost + "api/customer/getcustomers", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addCustomer = createAsyncThunk(
    'customer/addnewcustomer',
    async (customerData) => {
        let result = await fetch(serverHost + "api/customer/addnewcustomer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(customerData)
        })
        result = await result.json();
        return result;
    });
export const getCustomerDetails = createAsyncThunk(
    'customer/getCustomerDetails',
    async (customerId) => {
        let result = await fetch(serverHost + "api/customer/getcustomerdetails/" + customerId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addCustomerContact = createAsyncThunk(
    'customer/addcontact',
    async (yourData) => {
        let customerId = yourData.cid;
        let contactDatas = yourData.contactData;
        let result = await fetch(serverHost + "api/customer/addcontact/" + customerId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const getCustomerContacts = createAsyncThunk(
    "customer/getcontacts",
    async (customerId) => {
        let result = await fetch(serverHost + "api/customer/getcontact/" + customerId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const updateCustomerContact = createAsyncThunk(
    'customer/updatecontact',
    async (contactData) => {
        let contactDatas = contactData;
        let result = await fetch(serverHost + "api/customer/updatecontact", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const deleteCustomerContact = createAsyncThunk(
    'customer/contact/delete',
    async (contact_id) => {
        let contactid = contact_id;
        let result = await fetch(serverHost + "api/customer/deletecontact/" + contactid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });
export const resetCustomerPassword = createAsyncThunk(
    'customer/resetpassword',
    async (customerId) => {
        let password = { "reset_password": "Password" };
        let result = await fetch(serverHost + "api/customer/resetpassword/" + customerId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(password)
        })
        result = await result.json();
        return result;
    });
export const editCustomer = createAsyncThunk(
    'customer/editCustomer',
    async (customerData) => {
        let customerDatas = customerData;
        let result = await fetch(serverHost + "api/customer/editCustomer", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(customerDatas)
        })
        result = await result.json();
        return result;
    });

const customerDetailsSlice = createSlice({
    name: 'CustomerDetails',
    initialState: {
        loading: false,
        customer: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCustomers.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(getAllCustomers.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(getAllCustomers.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(addCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(addCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getCustomerDetails.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(getCustomerDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(getCustomerDetails.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(deleteCustomerContact.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(deleteCustomerContact.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(deleteCustomerContact.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateCustomerContact.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(updateCustomerContact.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(updateCustomerContact.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getCustomerContacts.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(getCustomerContacts.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(getCustomerContacts.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addCustomerContact.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(addCustomerContact.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(addCustomerContact.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(editCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(editCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(editCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(resetCustomerPassword.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(resetCustomerPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(resetCustomerPassword.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default customerDetailsSlice.reducer;