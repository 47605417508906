import { Helmet, HelmetProvider } from "react-helmet-async";
import '../mainlayout.css';
import TopNav from "../layout/TopNav";
import SideMenu from "../layout/SideMenu";
import HeaderNav from "../layout/HeaderNav";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { getAllMember } from "../../redux/memberDetails";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";

function Members() {

    const [members, setMembers] = useState([]);
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.membersDetails);

    const [mobNav,setMobNav]=useState(false);

    const memberuser_id = JSON.parse(localStorage.getItem('member-info')).member_id;
    const type = "all";

    const refreshTable = useCallback(() => {
        dispatch(getAllMember({ type, memberuser_id })).then((result) => {
            if (result.payload.error === "") {
                setMembers(result.payload.data);
            }
        });
    }, [dispatch, type, memberuser_id]);

    useEffect(() => {
        refreshTable();
    }, [refreshTable]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View All Employees | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Employees" valueMobNav={mobNav}/>
                <div className="right-total-body">
                    <HeaderNav navValue={["Employees", "View"]} />
                    <div className="fulldetailsbody">
                        <div className="toolbar-container">
                            <Link to="/employees/add" className="my-button bg-spblue"><i className="fas fa-user-plus"></i> Add Employee</Link>
                        </div>
                        <div className="details-table">
                            <table className="table_inner"><thead className="my_head">
                                <tr>
                                    <td className="normal_td table_blue">View</td>
                                    <td className="normal_td table_blue">Employee ID</td>
                                    <td className="normal_td table_blue">Role</td>
                                    <td className="normal_td table_blue">Name</td>
                                    <td className="normal_td table_blue">Email</td>
                                    <td className="normal_td table_blue">Gender</td>
                                    <td className="normal_td table_blue">Status</td>
                                    <td className="normal_td table_blue cell-w768-nodisplay">Aadhaar</td>
                                    <td className="normal_td table_blue">City</td>
                                    <td className="normal_td table_blue">Zip Code</td>
                                    <td className="normal_td table_blue">Joining Date</td>
                                </tr>
                            </thead>
                                <tbody className="my_body">
                                    {
                                        (members.length !== 0) ?
                                            members.map((member, index) =>
                                                <tr key={member.id}>
                                                    <td className="icon-tab"><Link to={"/employees/view/" + member.member_id}><i className="far fa-eye"></i></Link></td>
                                                    <td>{member.member_id}</td>
                                                    <td className="startwordcapital">{member.role}</td>
                                                    <td>{member.fname} {member.mname} {member.lname}</td>
                                                    <td>{member.email}</td>
                                                    <td className="startwordcapital">{member.gender}</td>
                                                    <td className="startwordcapital">{member.status}</td>
                                                    <td className="cell-w768-nodisplay">{member.aadhar}</td>
                                                    <td>{member.city}</td>
                                                    <td>{member.zipcode}</td>
                                                    <td>{member.science_date}</td>

                                                </tr>
                                            ) :
                                            <tr className="no-contact-data">
                                                <td colSpan={11} className="errordata">No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? <Loading Type="fourdots" /> : ""
            }
        </>
    );
}
export default Members;